import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { BaseService } from './base.service';


/**
 Provider pour les pages Profil et QCM 
*/
@Injectable({
  providedIn: 'root'
})
export class ClassesService extends BaseService {

  constructor(private http: HttpClient) { 
    super();
    this.getClasses();
  }


/**
  fin de l'URL pour la requête API des classes
*/
  actionGetClasses = '?action=app_get_class';

/**
* Récupère la liste de toutes les classes / niveaux scolaires
* @returns Un observable (objet JSON) 
*/

  public listeClasses: any; 
  
  // TODO choose getClasses() or getClassesAsync()?
  getClasses(){
    if(!this.listeClasses){
      this.http.get(this.postUrl+ this.actionGetClasses).subscribe((data) => {
        this.listeClasses = data;
      });
    }
    return this.http.get(this.postUrl+ this.actionGetClasses); 
  }

  private waitClasses;

  async getClassesAsync():Promise<any>{
    return new Promise(resolve => {
        if (this.listeClasses) {
          resolve(this.listeClasses);
        } else {
          this.waitClasses = setInterval(()=>{
            if (this.listeClasses) {
              clearInterval(this.waitClasses);
              resolve(this.listeClasses);
            }
          }, 100);
        }
    });
  }

}
