import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { LoadingController, ModalController } from "@ionic/angular";
import { AccountService } from "../services/account.service";
import { GlobalService } from "../services/global.service";
import Cropper from "cropperjs";

@Component({
	selector: "app-crop-img",
	templateUrl: "./crop-img.component.html",
	styleUrls: ["./crop-img.component.scss"]
})
export class CropImgComponent implements OnInit, AfterViewInit {
	static cropperData;
	static cropperImage;
	static croppedCanvas;
	private cropper: Cropper;
	fichierBase64: any;
	@ViewChild("fichier", { static: false }) fichier: ElementRef;

	constructor(
		private accountService: AccountService,
		private http: HttpClient,
		private modalCtl: ModalController,
		private globalService: GlobalService,
		private loadingController: LoadingController
	) {}

	ngOnInit() {}

	ngAfterViewInit() {
			this.cropper = new Cropper(this.fichier.nativeElement, {
					viewMode: 2,
					autoCropArea : 1,
					minContainerWidth: this.globalService.isCordova ? 350 : 600,
					minContainerHeight: this.globalService.isCordova ? 350 : 600,
					cropBoxResizable: true,
					zoomOnWheel: true,
				crop() {
					CropImgComponent.cropperData = this.cropper.getContainerData();
					CropImgComponent.croppedCanvas = this.cropper.getCroppedCanvas();
					CropImgComponent.croppedCanvas.base64 = this.cropper.getCroppedCanvas().toDataURL();
					CropImgComponent.cropperImage = this.cropper.getImageData();
					CropImgComponent.cropperImage.zoomLevel = CropImgComponent.cropperImage.width / CropImgComponent.cropperImage.naturalWidth;
				}
			});
	}

	fileChangeEvent(event: any): void {}

	dismiss() {
		this.modalCtl.dismiss({
			dismissed: true
		});
	}

	zoomOut() {
		if (CropImgComponent.cropperImage.zoomLevel) {
			CropImgComponent.cropperImage.zoomLevel -= 0.03;
			this.cropper.zoomTo(CropImgComponent.cropperImage.zoomLevel);
		}
	}

	zoomIn() {
		if (CropImgComponent.cropperImage.zoomLevel) {
			CropImgComponent.cropperImage.zoomLevel += 0.03;
			this.cropper.zoomTo(CropImgComponent.cropperImage.zoomLevel);
		}
	}

	/**
	 * Valider et recadrer la photo
	 */

	async cropFinalImg() {
		this.accountService.user.profilPicture = CropImgComponent.croppedCanvas.base64;
		const formData = new FormData();

		formData.append("action", "app_save_photo");
		formData.append("photoupload", CropImgComponent.croppedCanvas.base64);
		this.chargement();
		this.http.post("https://www.planeteprofs.com/wp-content/plugins/simple-chat/apiProf.php", formData).subscribe(async data => {
			await this.loadingController.dismiss();
			this.modalCtl.dismiss();
		});
	}

	/**
	 * Chargement
	 */
	async chargement() {
		const loading = await this.loadingController.create({
			message:  "Sauvegarde en cours ...",
		});
		await loading.present();
	}
}
