import { UserCredential } from "./user-credential";

export class User {
    public id: number;
    public firstName: string;
    public lastName: string;
    public auth: UserCredential;
    public channelId: number;
    public email: string;
    public displayName: string; //=currentuser
    public tel: string;
    public classID: number;
    public className: string;
    public etablissement: string;
    public role: string;
    public token: string;
    public profilPicture: string;
    public profileLoaded = false;
}
