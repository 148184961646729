import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AccountImplService } from './account.Impl.service';
import { AccountService } from './account.service';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class SsoService implements CanActivate {
  private toast;

  constructor(private accountService: AccountService, private router: Router, private toastController : ToastController,
              private globalService : GlobalService) { }

 async routeAccessUnabledToaster() {
    this.toast = await this.toastController.create({
			header: `Rempli le formulaire pour pouvoir accéder aux messages`,
			position: this.globalService.isCordova ? "bottom" : "top",
			duration: 5000,
      });
    this.toast.present();
    }

  checkChatNavigationPermission() {
    return new Promise((resolve, reject) => {
      this.accountService.getUserData().then(() => {
        resolve(this.accountService.user.firstName.length === 0 &&
        this.accountService.user.lastName.length === 0
         );
      });
    });
  }


  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      this.checkChatNavigationPermission().then(notAllowed => {
          if (notAllowed) {
            this.router.navigateByUrl("profile").then(() => {
              if (this.toast) {
                this.toast.dismiss();
              }
              this.routeAccessUnabledToaster();
            });
            resolve(false);
          }
          resolve(true);
      });
    });
  }

}