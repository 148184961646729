import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavController, LoadingController,  } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-cours',
  templateUrl: './cours.page.html',
  styleUrls: ['./cours.page.scss'],
})

export class CoursPage {
  
  untrustedUrl: string;
  urlSafe: SafeResourceUrl;
  asyncResult;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    private sanitizer: DomSanitizer
    ) 
    { 
      this.urlChargement();
      //récupère la value correspondante à l'URL passée lors de la demande de création du modal
      this.untrustedUrl = navParams.get("values");
      //sécurise cette URL
      this.urlSafe = sanitizer.bypassSecurityTrustResourceUrl(this.untrustedUrl);
    } 

  /**
   * Sert à gérer le chargement du modal cours
   * 
   */
  async urlChargement() {
    
    const loading = await this.loadingController.create({
      message:'Chargement en cours...',
      duration: 1000
    });
    await loading.present();
    const { role, data } = await loading.onDidDismiss();
  }

  /**
   * Sert à gérer la fermeture du modal cours
   * 
   */
  public closeModal() {
    this.modalCtrl.dismiss({
    'dismissed': true
    });
  }   
}

