import { Component, OnInit } from "@angular/core";
import { AccountService } from "src/app/services/account.service";
import { HttpClient } from "@angular/common/http";
import { BaseService } from "src/app/services/base.service";
import { GlobalService } from "src/app/services/global.service";
import { Subject, Subscription } from "rxjs";
import { takeUntil, map } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import { ChatMessage } from "src/app/models/chatMessage";
import { ChatService } from "src/app/services/chat.service";
import { AnalyticService, EventType } from "src/app/services/analytic.service";
import { Location } from "@angular/common";
import { DarkModeService } from "src/app/services/dark-mode.service";
import { OneSignalService } from "src/app/services/one-signal.service";

@Component({
	selector: "app-messages",
	templateUrl: "./messages.page.html",
	styleUrls: ["./messages.page.scss"]
})
export class MessagesPage extends BaseService implements OnInit {
	pendingHTTPRequest = new Subject<void>();
	data_eleve;
	page = 0;
	data_searchEleve = [];
	data_primaryPageEleve = [];
	reset;
	isChatEnLigne = false;
	spinner;
	data: boolean = false;
	public chatMessages: ChatMessage[] = [];
	constructor(
		private accountService: AccountService,
		private http: HttpClient,
		private analyticService : AnalyticService,
		private router: Router,
		private route: ActivatedRoute,
		private chatService: ChatService,
		public globalService: GlobalService,
		public oneSignalSerivce : OneSignalService,
		public location: Location,
		public darkMode: DarkModeService
	) {
		super();
	}

	ngOnInit() {
		this.getEleves();
	}

	ionViewWillEnter() {
		this.globalService.decoLoader();
		this.analyticService.sendAnalytics(EventType.app, "App Discussions");
		this.oneSignalSerivce.initOneSignal();
	}

	ionViewWillLeave() {
		this.chatService.unsubscribeCheckNewMessage();
	}

	async getEleves() {
		let userData = await this.accountService.getUserData();
		this.loadEleve();
		userData = true;
		if (userData) {
			if (this.accountService.user.id === 52) {
				this.isChatEnLigne = true;
				console.log("Super prof en ligne");
			} else if (this.accountService.user.role === "professeur") {
				console.log("professeur en ligne");
			}
		}
	}

	loadEleve() {
		this.http.get(`${this.postUrl}?action=app_get_eleves_pour_profs&start=${this.page}`).subscribe((data: any) => {
			if (this.page === 0) {
				this.data_eleve = data;
				this.reset = false;
				this.data_primaryPageEleve = this.data_eleve;
				console.log(this.data_eleve);
			}
			if (this.page != 0 && this.reset != true) {
				// crée et remplis la data_eleve
				for (let i = 0; i < data.length; i++) {
					this.data_eleve.push(data[i]);
				}
			}
			//fait attention à bien supprimer les éléments sans rien
			for (let i = 0; i < this.data_eleve.length; i++) {
				if (this.data_eleve[i].title === null) {
					this.data_eleve.splice([i], 1);
				}
			}
		});

		//retourne la data_eleve un fois toutes les vérif terminées
		return this.data_eleve;
	}

	doRefresh() {
		this.reset = true;
		this.page = 0;
		this.loadEleve();
		// setTimeout(() => {
		// 	console.log("Async operation has ended");
		// 	event.target.complete();
		// }, 800);
	}

	loadData(event) {
		this.page = this.page += 12;
		this.loadEleve();
		setTimeout(() => {
			event.target.complete();
		}, 500);
	}

	//Fonction search
	searchEleve(event: any) {
		//annule la requete précédente si un nouveau event est activé
		this.cancelPendingRequests();
		this.spinner = true;
		//récupère ce que l'utilisateur tappe dans la barre de recherche
		let value = event.target.value;
		if (value === "") {
			this.data_eleve = this.data_primaryPageEleve;
			this.spinner = false;
			return this.data_eleve;
		}
		if (value && value.trim() != "") {
			this.getSearchEleve(value);
		}
	}

	getSearchEleve(value) {
		let request = this.http
			.get(`${this.postUrl}?action=app_get_eleves_pour_profs&search=${value}`)
			// renvoie un observable qui est déclenché avec le next
			.pipe(takeUntil(this.onCancelPendingRequests()));
		request.subscribe({ next: value => (this.data_eleve = value), error: err => console.error(err), complete: () => (this.spinner = false) });
		// this.data_searchEleve = data;
		this.data_eleve = this.data_eleve.filter(eleve => {
			return eleve.title.toLowerCase().indexOf(value.toLowerCase()) > -1;
		});
	}

	// annule une requete en cours
	public cancelPendingRequests() {
		this.pendingHTTPRequest.next();
	}

	public onCancelPendingRequests() {
		return this.pendingHTTPRequest.asObservable();
	}

	public presentEleve(index?) {
		console.log(this.data_eleve[index]._id);
		this.http.post(`${this.postUrl}?action=app_go_to_channel`, this.data_eleve[index]._id).subscribe(data => {
			console.log(data);
			//this.chatService.getMessages();
			this.goToChatPage();
		});
	}

	async goToChatPage() {
		const redirectUrl = this.route.snapshot.queryParams["./chat/chat.page.ts"] || "/chat";
		this.router.navigate([redirectUrl]);
	}
}
