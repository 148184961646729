import { Animation, createAnimation } from '@ionic/angular';

export const modalLeaveByBot = (baseEl: HTMLElement): Animation => {
    const baseAnimation = createAnimation();
    const backdropAnimation = createAnimation();
    const wrapperAnimation = createAnimation();
    const wrapperEl = baseEl.querySelector('.modal-wrapper')!;
  
    backdropAnimation
      .addElement(baseEl.querySelector('ion-backdrop')!)
      .fromTo('opacity', 'var(--backdrop-opacity)', 0.0);
  
    wrapperAnimation
      .addElement(wrapperEl)
      .keyframes([
        { offset: 0, opacity: 0.99, transform: 'translateY(0px)' },
        { offset: 1, opacity: 0.99, transform: 'translateY(100vh)' }
      ])
      .duration(500);
  
    return baseAnimation
      .addElement(baseEl)
      .easing('cubic-bezier(0.47,0,0.745,0.715)')
      .duration(200)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };