import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnChanges } from "@angular/core";
import { GlobalService } from "src/app/services/global.service";
import { ModalController, LoadingController } from "@ionic/angular";
import { BaseService } from "src/app/services/base.service";
import { HttpClient, HttpHandler } from "@angular/common/http";
import { ChatMessage } from "src/app/models/chatMessage";
import { ChatService } from "src/app/services/chat.service";
import { AccountService } from "src/app/services/account.service";
import { DarkModeService } from "src/app/services/dark-mode.service";

@Component({
	selector: "app-calculatrice",
	templateUrl: "./calculatrice.page.html",
	styleUrls: ["./calculatrice.page.scss"]
})
export class CalculatricePage extends BaseService implements AfterViewInit {
	constructor(
		public globalService: GlobalService,
		public modalCtrl: ModalController,
		public http: HttpClient,
		public chatService: ChatService,
		public loadingController: LoadingController,
		private accountService: AccountService,
		public darkMode: DarkModeService
	) {
		super();
	}

	public calc = null;

	ngAfterViewInit() {
		setTimeout(() => {
			this.calc = new (window as any).MathEditor(
				"calculatrice",
				this.sendEquation,
				localStorage.getItem("classe_id"),
				this.globalService.calcOptions
			);
		}, 1000);
	}

	/**
	 * envoi le latex de la calculatrice au serveur et poste un faux message
	 */

	sendEquation = () => {
		this.chargement();

		const datapost = {
			equation: this.calc.getLatex()
		};
		this.http.post(this.postUrl + "?action=app_save_equation", datapost).subscribe(data => {
			// const message: ChatMessage = {
			// 	title: this.accountService.user.role === "professeur" ? "Prof en Poche" : this.accountService.user.displayName,
			// 	from: this.accountService.user.id,
			// 	role: this.accountService.user.role,
			// 	icon: null,
			// 	showtitle: true,
			// 	message: "envoi equation",
			// 	message_date: "Now",
			// 	classes: "ToDelete",
			// 	url: "",
			// 	url_image: "",
			// 	type: "",
			// 	heure: null
			// };

			// this.chatService.chatMessages = this.chatService.chatMessages.concat(message);

			this.loadingController.dismiss();
			this.dismiss();
		});
	};

	/**
	 * quitte le modal
	 */

	dismiss() {
		this.modalCtrl.dismiss({
			dismissed: true
		});
	}

	/**
	 * fait apparaître le loading
	 */

	async chargement() {
		const loading = await this.loadingController.create({
			message: "Envoi en cours..."
		});
		await loading.present();
	}
}
