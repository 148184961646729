import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseService } from "./base.service";
import { Platform, LoadingController, NavController, MenuController, ToastController } from "@ionic/angular";
import { AccountService } from "./account.service";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { Subject, Subscription } from "rxjs";
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

@Injectable({
	providedIn: "root"
})
export class GlobalService extends BaseService {
	  /** Carousel */
    public slidesData;

    /** logo */
    public urlSocialLink;
  
    /** Calculette */
    public calcOptions: string = null
  
    /** geolocalisation */
    public geoResult: object = null
  
  	// orientation:
	public screenOrientationSubscription: Subscription;
	public screenOrientationChangeEvent: Subject<boolean>;
	public isLandscape: boolean;
	public isMobileLandscape: boolean;
	public waitingLandscapeModeSubscription: Subscription;

    /** Platform */
    public isMobile;
    public isTablet;
    public isURL;
    public isDesktop;
    public isBrowser;
    public isCordova;
    public isApp;
    public isIos;
    public isAndroid;
    public onHome: boolean;
    public onSignup: boolean;
  
  
    public exportRoot: any;
    public loader;
    showLoader: boolean;
  
    public splitPaneStatus: boolean;
    public customMatieresIcons;
    public logoAnimEnd: boolean;
    public isSafari: boolean;
	  public nativeKeyboardStatus: boolean;
	  public nativeKeyboardHeight: any;
    public is4k: boolean;
    constructor(
      private http: HttpClient,
      public platform: Platform,
      public toastController : ToastController,
      private loadingCtrl: LoadingController,
      public menuCtrl: MenuController,
      public androidPermissions: AndroidPermissions,
      public screenOrientation: ScreenOrientation
    ) {
        super();
        this.platform.ready().then(() => {
          this.screenOrientationChangeEvent = new Subject<boolean>();
          this.initPlatformAndOrientationVariables();
        });
  
        this.customMatieresIcons = new Array();
        this.http.get(this.postUrl + "?action=app_get_conf").subscribe((data: any) => {
          // si tout va bien va chercher les slides à jour dans le serveur
          this.carousel(data)
          this.socialLink(data);
          this.setGpsContent(data);
          this.getCalc(data);
          this.customMatieresIcons = JSON.parse(data.customMatieresIcons);
        }, error => {
          this.carousel(this.data_default);
          this.socialLink(this.data_default);
          this.setGpsContent(this.data_default);
          this.getCalc(this.data_default);
          this.customMatieresIcons = this.data_default.customMatieresIcons;
        });
        this.showLoader = false;
    }
  
  
    // <ion-nav-view  ng-controller="AboutCtrl"></ion-nav-view>
    // <div id="global_loader" class="loader hidden_loading">
    //     <!--<img src="https://www.planeteprofs.com/wp-content/themes/Pieber/images/big-loader.gif"/>-->
  
    // this.splashScreen.show();
  
    scrollToElement(id: string) {
      console.log("document.getElementById(id) = ", document.getElementById(id));
      setTimeout(() => {
        document.getElementById(id).scrollIntoView();
        // document.getElementById(id).setAttribute('height', 'calc(100% + ' + this.nativeKeyboardHeight + 'px');
      }, 500);
    }
  
    async loginLoader() {
        this.loader = await this.loadingCtrl.create({
          spinner: null,
          cssClass: 'loaderHide',
        });
        if (this.loader) {
          this.showLoader = true;
        }
        this.loader.present();
        return this.loader;
    }
    
    decoLoader() {
      if (this.loader) {
          this.showLoader = false;
          return this.loader.dismiss();
      };
    }
  
    async openSearchLoading(message) {
      this.loader = await this.loadingCtrl.create({
        message,
        duration: 1000
      })
      this.loader.present();
      return this.loader;
    }
  
    closeSearchLoading() {
      if (this.loader) {
        return this.loader.dismiss();
      }
    }
  
    androidPermission() {
      return new Promise((resolve, reject) => {
        this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE).then(
          result => {
            if (!result.hasPermission) {
              this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE).then(
                result => {
                  if (!result.hasPermission) {
                    alert("Permission d'accés aux fichiers refusée");
                    reject();
                  } else {
                    resolve(true);
                  }
                });
            } else {
              resolve(true);
            }
          });
      });
    }

    async toastControllerMessage(message, duration = 3000) {
      const toast: any = await this.toastController.create({
        message,
        duration,
        position: "bottom"
      });

      toast.present();
    }
    carousel(data) {
     this.slidesData = data.tabStory;
    }
  
    async initPlatformAndOrientationVariables() {
      // console.log(`analytics : Platform = ${this.platform.platforms()}`);
  
      // this.isMobile = ((this.platform.is("mobile") && this.platform.is("mobileweb")) && !this.platform.is("tablet") && !this.platform.is("desktop"));
      this.isMobile = (this.platform.is("mobile") || this.platform.is("mobileweb")) && !this.platform.is("tablet") && !this.platform.is("desktop");
      this.isTablet = (this.platform.is("tablet") && !this.platform.is("desktop"));
      this.isDesktop = this.platform.is("desktop");
      this.isBrowser = (this.platform.is("desktop") || this.platform.is("mobileweb")) && !this.isCordova;
      this.isCordova = this.platform.is("cordova");
      this.isApp = !(window.location.port === "8100");
      this.isIos = this.platform.is("ios");
      this.isAndroid = this.platform.is("android");
      this.is4k = this.check4k();
  
      if (this.isCordova) {
        await this.checkPlatformLandscapeMode();
      }
      console.log("Mobile : " + this.isMobile);
      console.log("tablet : " + this.isTablet);
      console.log("desktop : " + this.isDesktop);
      console.log("browser : " + this.isBrowser);
      console.log("Cordova : " + this.isCordova);
      console.log("application : " + this.isApp);
      console.log("Ios : " + this.isIos);
      console.log("android : " + this.isAndroid);
      console.log("isSafari? = " + this.isSafari);
      console.log("isLandscape? = " + this.isLandscape);
      console.log("global platform init -- isMobileLandscape? = " + this.isMobileLandscape);
    }
  
    async checkPlatformLandscapeMode() {
      if (!this.isSafari || (this.isSafari === undefined && this.screenOrientation)) {
        console.log("this.screenOrientation.type = " + this.screenOrientation.type);
        this.isLandscape =
          (this.screenOrientation.type === "landscape-primary" ||
            this.screenOrientation.type === "landscape-secondary" ||
            this.screenOrientation.type === "landscape") &&
          this.screenOrientation.type !== undefined;
        this.isMobileLandscape = this.isMobile && this.isLandscape;
      } else if (this.isSafari) {
        console.log(window.innerHeight, window.innerWidth);
        if (window.innerHeight < window.innerWidth) {
          this.isLandscape = true;
        } else {
          this.isLandscape = false;
        }
        this.isMobileLandscape = this.isMobile && this.isLandscape;
      }
      console.log("checkPlatformLandscapeMode -- isLandscape? = " + this.isLandscape);
      console.log("checkPlatformLandscapeMode -- isMobileLandscape? = " + this.isMobileLandscape);
    }

    check4k() {
      let width, height, test;
      width = window.innerWidth;
      height = window.innerHeight;
      if( height > width) {
          test = height;
      } else {
          test = width;
      }
      console.log("4k = " + (test > 3839));
      return ( test > 3839 ) 
      }

    	// detect orientation changes
	observeScreenOrientation() {
		this.screenOrientationSubscription = this.screenOrientation.onChange().subscribe(() => {
			// analytics event "Replay Record Button clicked"
			// console.log(
			// 	`analytics for sid ${localStorage.getItem("connect_sid")}` +
			// 		` : Screen Orientation Changed to ${this.screenOrientation.type}`
			// );

      this.initPlatformAndOrientationVariables();
      // await this.checkPlatformLandscapeMode();
      if (!this.isCordova) {
        this.screenOrientationChangeEvent.next(
          (this.screenOrientation.type === "landscape-primary" ||
            this.screenOrientation.type === "landscape-secondary" ||
            this.screenOrientation.type === "landscape" ||
            this.isLandscape) &&
            this.screenOrientation.type !== undefined
        );
      }
		});
	}
  
  async presentLoading() {
    const loader =  await this.loadingCtrl.create({
    });

    loader.present();
   
    return loader;
  }


    socialLink(data) {
      this.urlSocialLink = [
        {
          url: data.facebookUrl,
          icon: "facebook",
        },
        {
          url: data.instagramUrl,
          icon: "instagram",
        },
        {
          url: data.websiteUrl,
          icon: "earth",
        },
        {
          url: data.twitterUrl,
          icon: "twitter",
        },
      ];
    }

    getCalc(data) {
      this.calcOptions = data.calcOptions;
    }

    setGpsContent(data){
      this.geoResult = {
        geoOkTitle: data.geoOkTitle,
        geoOk: data.geoOk,
        geoKoTitle: data.geoKoTitle,
        geoFermer: data.geoFermer,
        geoFermerTitle: data.geoFermerTitle,
        geoKo: data.geoKo,
        geoNoGpsTitle: data.geoNoGpsTitle,
        geoNoGps: data.geoNoGps,
      }
    }
    getGpsContent(): Promise<any> {
      return new Promise((resolve) => {
        resolve(this.geoResult);
      })
    }

    ngOnDestroy() {
      // window.removeEventListener("resize", () => this.updateCabriSizeOnceWindowResized());
      // console.log("window.removeEventListener('resize', () => this.updateCabriSizeOnceWindowResized())");
      this.screenOrientationSubscription.unsubscribe();
      console.log("this.screenOrientationSubscription.unsubscribed !");
    }
}
