import { Injectable } from "@angular/core";
import { AccountService } from "./account.service";
import { Observable, throwError, BehaviorSubject } from "rxjs";
import { User } from "../models/user";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { BaseService } from "./base.service";
import { UserCredential } from "../models/user-credential";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingController } from "@ionic/angular";
import { ClassesService } from "./classes.service";
import { catchError } from "rxjs/operators";
import { GlobalService } from "./global.service";
import { AnalyticService, EventType } from "./analytic.service";
import { Route } from "@angular/compiler/src/core";
import { OneSignalService } from "./one-signal.service";

@Injectable({
	providedIn: "root"
})
export class AccountImplService extends BaseService implements AccountService {
	
	constructor(private http: HttpClient, public loadingController: LoadingController,
				private router: Router, private route: ActivatedRoute) {
		super();
		this.user = new User();
		this.auth = new UserCredential();
		this.connectSid = localStorage.getItem("connect_sid");
		if (!this.connectSid || this.connectSid === "") {
			this.connectSid = this.returnHash();
			localStorage.setItem("connect_sid", this.connectSid);
		}
	}
	public analyticService: AnalyticService;
	public oneSignalService: OneSignalService;
	actionUrlUserConnecte = "?action=app_user_est_connecte2";
	actionUrlGetProfile = "?action=app_get_profile";
	public user: User;
	public dataPost: any;
	public auth: UserCredential;
	connectSid;
	public globalService;
	public listeCours = new Array();
	classesService: ClassesService;
	public skilleosUser: boolean;

	// TODO trigger @ right time or make a component from revision page:
	/**
	 * Fais une requête au serveur pour obtenir les cours et en extrait un tableau affichable
	 */

	public listeCoursClassID: number;
	public listeCoursClassName: string;

	sendEmail(): Observable<boolean> {
		const formData = new FormData();
		formData.append("action", "app_send_email");
		formData.append("email", this.user.email);
		return this.http.post<boolean>(this.postUrl, formData);
	}

	/** LOCALSTORAGE */

	storeLocalStorValue(key: string, value) {
		return localStorage.setItem(key, value);
	}

	getLocalStorValue(key) {
		return localStorage.getItem(key);
	}

	checkUserPermission(value: string = null): boolean {
		let haveRight: boolean;
		if (!value) {
			haveRight =
				localStorage.getItem("codeclass") != null ||
				localStorage.getItem("level") != null ||
				localStorage.getItem("codeClassProf") != null ||
				localStorage.getItem("sentencesTop") != null ||
				localStorage.getItem("sentencesCurent") != null ||
				localStorage.getItem("sentencesBottom") != null;
		} else {
			haveRight = localStorage.getItem(value) != null;
		}
		return haveRight;
	}

	checkUserConnected(): boolean {
		const userConntected =
			(localStorage.getItem("codeclass") != null && localStorage.getItem("level") != null) || localStorage.getItem("codeClassProf") != null;
		return userConntected;
	}

	removeLocalStorKey(key) {
		return localStorage.removeItem(key);
	}

	/** FIN LOCALSTORAGE  */

	/**
	 * Récupération des infos utilisateur à la connexion
	 * @returns Promesse qui stocke les informations utilisateur dans la classe User
	 */

	async getUserData() {
		// vérifier que l'utilisateur est bien connecté
		return new Promise((resolve, reject) => {
			if (this.user.profileLoaded) {
				resolve(true);
				return;
			}
			this.http.get(this.postUrl + this.actionUrlUserConnecte).subscribe(
				(data: any) => {
					if (data[0] === "false") {
						this.handleError(data[0]);
						this.presentLoading();
						this.router.navigateByUrl("home");
						reject();
					}
					if (data[0] === "true_eleve" || data[0] === "true_prof") {
						this.auth.connected = true;
					}
					// récupérer les infos profil utilisateur
					this.http.get(this.postUrl + this.actionUrlGetProfile)
					  .subscribe(
						(data2: any) => {
							this.loadUserData(data2);
							this.user.profileLoaded = true;
							resolve(true);
						},
						error => {
							console.log(error);
							reject(error);
						}
					);
				},
				error => {
					reject(error);
				}
			);
		});
	}

	loadUserData(data2) {
		this.auth.localKey = data2[0].token;
		this.auth.login = data2[0].Email;
		this.user.id = data2[0].currentuserID;
		this.user.firstName = data2[0].first_name;
		this.user.lastName = data2[0].last_name;
		this.user.channelId = data2[0].channel_id;
		this.user.etablissement = data2[0].etablissement;
		this.user.classID = Number(data2[0].classe_id);
		this.listeCoursClassID = Number(data2[0].classe_id);
		this.user.className = data2[0].classeeleve;
		this.listeCoursClassName = data2[0].classeeleve;
		this.user.email = data2[0].Email;
		this.user.displayName = data2[0].currentuser;
		this.user.tel = data2[0].NumTel;
		this.user.role = data2[0].role;
		this.user.auth = this.auth;
		this.user.profilPicture = data2[0].profile_picture;
		this.user.profileLoaded = true;
		if (this.user.email.includes("skilleos")) {
			this.skilleosUser = true;
		}
	}

	unloadUserData() {
		for (const property in this.user) {
			if (this.user[property]) {
				this.user[property] = null;
			}
		  }
	}

	// Ionic Loading : animation de chargement à la soumission du formulaire
	async presentLoading() {
		const loading = await this.loadingController.create({
			cssClass: "my-custom-class",
			message: "Vous avez été déconnecté, retour vers la page de connexion...",
			duration: 2000
		});
		await loading.present();
		const { role, data } = await loading.onDidDismiss();
	}

	returnHash() {
		const abc = "abcdefghijklmnopqrstuvwxyz1234567890".split("");
		let token = "";
		for (let i = 0; i < 32; i++) {
			token += abc[Math.floor(Math.random() * abc.length)];
		}
		return token; // Will return a 32 bit "hash"
	}

	/**
	 * Accèder à globalService via un setter pour éviter CircularDependency
	 */

	setGlobalService(globalService: GlobalService) {
		this.globalService = globalService;
	}

	/**
	 * Accèder à analyticService via un setter pour éviter CircularDependency
	 */

	setAnalyticService(analyticService) {
		this.analyticService = analyticService;
	}

	/**
	 * Accèder à oneSignal via un setter pour éviter CircularDependency
	 */

	setOneSignalService(oneSignal) {
		this.oneSignalService = oneSignal;
	}

	async logout() {
		return new Promise(async (resolve, reject) => {
			await this.globalService.loginLoader();
			this.analyticService.sendAnalytics(EventType.action, "Déconnexion");
			const redirectUrl = this.route.snapshot.queryParams["./home/home.page"] || "/home";
			this.http.get(this.postUrl + "?action=app_deconnexion").subscribe((data: any) => {
				this.unloadUserData();
				if (!this.globalService.isCordova) {
					this.oneSignalService.initOneSignal().then(() => {
						this.oneSignalService.deconnect();
					});
				} else {
					this.oneSignalService.deconnect();
				}

				this.router.navigate([redirectUrl]);
				this.globalService.decoLoader();
				localStorage.removeItem("role");
				localStorage.removeItem("tokens");
				localStorage.removeItem("connect_sid");
				resolve(true);
			}, () => {
				this.globalService.decoLoader();
				reject(false);
			});
		});
	}

	async getListeCours() {
		this.listeCours = new Array();

		const classes = await this.classesService.getClassesAsync();
		if (!this.listeCoursClassID) {
			this.listeCoursClassID = 3;
		}
		const classe = classes.find(item => item.id === this.listeCoursClassID);

		this.listeCoursClassName = classe.name;
		this.http.post(this.postUrl + "?action=app_get_list_cours&id_classe=" + this.listeCoursClassID, null).subscribe(infoCours => {
			for (const key in infoCours) {
				if (infoCours.hasOwnProperty(key)) {
					if (!this.listeCours[infoCours[key].matiere]) {
						this.listeCours[infoCours[key].matiere] = { open: false, cours: [] };
					}
					this.listeCours[infoCours[key].matiere].cours.push(infoCours[key]);
				}
			}

			for (const key in this.listeCours) {
				if (this.listeCours.hasOwnProperty(key)) {
					const element = this.listeCours[key].cours;
					element.sort((a, b) => {
						const textA = a.name.toUpperCase();
						const textB = a.name.toUpperCase();
						return textA < textB ? -1 : textA > textB ? 1 : 0;
					});
					this.listeCours[key].cours = element;
				}
			}
		});
	}
}
