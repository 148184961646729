import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Platform, MenuController, ModalController, AlertController, ToastController } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AccountService } from "./services/account.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseService } from "./services/base.service";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { MediaObject } from "@ionic-native/media/ngx";
import { EventsService } from "./services/events.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { GlobalService } from "./services/global.service";
import { OneSignalService } from "./services/one-signal.service";
import { OneSignal } from "@ionic-native/onesignal/ngx";
import { AbonnementPage } from "./page/modal/abonnement/abonnement.page";
import { DarkModeService } from "./services/dark-mode.service";
import { CropImgComponent } from "./crop-img/crop-img.component";
import { modalEnterByBot } from "./models/animation/modalEnterByBot";
import { modalLeaveByBot } from "./models/animation/modalLeaveByBot";
import { RecordService } from "./services/record.service";
import { AnalyticService, EventType } from "./services/analytic.service";
import { ClassesService } from "./services/classes.service";
import { ChatService } from "./services/chat.service";
import { SsoService } from "./services/sso.service";
import { SafariService } from "src/app/services/safari.service";
import { Keyboard } from "@ionic-native/keyboard/ngx";
import { Location } from "@angular/common";

@Component({
	selector: "app-root",
	templateUrl: "app.component.html"
})
export class AppComponent extends BaseService implements OnInit {
	MENU_ELEVE_STUDENT_FEEDBACK = "Donner mon avis";
	MENU_TEACHER_FEEDBACK = "Votre avis nous intéresse";
	menuEleve = [
		{ title: "Messages", url: "/chat", icon: "chatbox", skilleos: true },
		{ title: "Mes fiches", url: "/gallery/grille", icon: "images", skilleos: true },
		{ title: "Profil", url: "/profile", icon: "person", skilleos: true },
		{ title: "Abonnements", url: "/abonnement", icon: "people", click: "abonnementModal()", skilleos: false },
		{ title: "Service client", url: "/contact", icon: "mail-sharp", skilleos: false },
		{ title: this.MENU_ELEVE_STUDENT_FEEDBACK, icon: "clipboard", skilleos: false },
		{ title: "Déconnexion", icon: "key" }
	];
	menuProf = [
		{ title: "Discussions", url: "/messages", icon: "chatbubbles" },
		{ title: "QCM", url: "/qcm", icon: "bulb" },
		{ title: "Chat", url: "/chat", icon: "chatbox" },
		{ title: "Profil", url: "/profile", icon: "person" },
		{ title: "Mes fiches", url: "/gallery/grille", icon: "images" },
		{ title: "Service client", url: "/contact", icon: "mail-sharp" },
		{ title: this.MENU_TEACHER_FEEDBACK, icon: "clipboard" },
		{ title: "Déconnexion", icon: "key" }
	];

	scriptsPeP: any;
	scriptsGlobal;
	url_account = "https://www.planeteprofs.com";
	showSplash = true;
	source: any;
	progress = 0;
	isBrowser;
	audioFile: MediaObject;
	dataMenu: any;
	media: any;
	file: any;
	iosVerif = false;
	urlSocialLink = [];
	deconnexion: any;
	profilPicture: any;
	profilePicTimesChecked = 0;
	countNumberNewMessages = 0;
	lowScreenResolution = false;
	toaster;

	@ViewChild("selectFile", { static: false }) selectFile: ElementRef;

	constructor(
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		public accountService: AccountService,
		public events: EventsService,
		public http: HttpClient,
		public screenOrientation: ScreenOrientation,
		private route: ActivatedRoute,
		public router: Router,
		public globalService: GlobalService,
		public recordService: RecordService,
		public menuCtrl: MenuController,
		public oneSignal: OneSignalService,
		private OneSignal: OneSignal,
		public toastController: ToastController,
		public modalController: ModalController,
		public darkMode: DarkModeService,
		private alerteController: AlertController,
		public classesService: ClassesService,
		public ssoService: SsoService,
		public oneSingalService: OneSignalService,
		public chatService: ChatService,
		public analyticService: AnalyticService,
		public safariService: SafariService,
		public keyboard: Keyboard,
		public location: Location
	) {
		super();
		this.globalService.onHome = false;
		this.globalService.onSignup = false;
		this.isBrowser = this.platform.is("desktop") || this.platform.is("mobileweb");
		// ANALYTICS

		this.globalService.observeScreenOrientation();
		this.detectPlatform();
		this.initializeApp();
		this.accountService.classesService = this.classesService;
		this.subscribeToKeyboardEvents();
	}

	subscribeToKeyboardEvents() {
		window.addEventListener("keyboardWillShow", (event: any) => {
		});
		window.addEventListener("keyboardDidShow", (event: any) => {
			this.globalService.nativeKeyboardHeight = event.keyboardHeight;
			this.globalService.nativeKeyboardStatus = true;
		});
		window.addEventListener("keyboardWillHide", () => {
		});
		window.addEventListener("keyboardDidHide", () => {
			this.globalService.nativeKeyboardStatus = false;
		});
	}

	ngOnInit() {
		// this.globalService.socialLink().then(() => {
		// 	console.log("social = ", this.globalService.urlSocialLink);
		// });
	}

	sendLinkAnalytic(link) {
		this.analyticService.sendAnalytics(EventType.action, "App Open Link", link);
	}

	// get numberNewMessages() {
	// 	return ChatService.countNewMessages;
	// 	return ChatService.countNewMessages;
	// }

	feedBackLink() {
		const link = "https://profenpoche.typeform.com/to/rsRe54";
		this.analyticService.sendAnalytics(EventType.action, "App Open Link", link);
		window.open(link, "_blank");
	}

	ionViewWillEnter() {}

	ionViewDidLoad() {}

	ionViewDidEnter() {}

	splitPaneStatus(e) {
		if (e.detail.visible) {
			this.globalService.splitPaneStatus = true;
		} else {
			this.globalService.splitPaneStatus = false;
		}
	}

	setupPush() {
		// iOS Settings (optional)
		const iosSettings = {};
		iosSettings["kOSSettingsKeyAutoPrompt"] = false;
		iosSettings["kOSSettingsKeyInAppLaunchURL"] = false;

		this.OneSignal.startInit("47930da2-dba2-43c3-b86f-c494da6df6d4", "159380755348");
		this.OneSignal.inFocusDisplaying(this.OneSignal.OSInFocusDisplayOption.None);

		this.OneSignal.handleNotificationReceived().subscribe(data => {
			let msg = data.payload.body;
			let title = data.payload.title;
			let additionalData = data.payload.additionalData;
		});

		this.OneSignal.handleNotificationOpened().subscribe(data => {
			let additionalData = data.notification.payload.additionalData;
		});

		this.OneSignal.endInit();
	}

	isHomeRoute() {
		// return true;
	}

	checkIfSafari() {
		if (this.safariService.onSafari("Safari")) {
			this.globalService.isSafari = true;
		} else {
			this.globalService.isSafari = false;
		}
		return;
	}

	initializeApp() {
		this.platform.ready().then(async () => {
			this.checkIfSafari();
			if (this.platform.is("cordova")) {
				this.splashScreen.hide();
				this.statusBar.overlaysWebView(false);
				this.statusBar.backgroundColorByHexString("#847ff5");
			}
			this.platform.backButton.subscribeWithPriority(10, () => {
				this.location.back();
			});
			this.accountService.setOneSignalService(this.oneSingalService);
			this.accountService.setAnalyticService(this.analyticService);
			this.accountService.setGlobalService(this.globalService);
			// this.statusBar.styleDefault();
			window.addEventListener("resize", event => this.updateScreenSize());
			if (this.chatService.displayLogo()) {
				this.lowScreenResolution = true;
			}

			if (this.platform.is("desktop")) {
				this.detectScreenVisibility();
			}

			this.showSplash = false;
			if (this.platform.is("cordova") || this.platform.is("mobile")) {
				this.analyticService.sendAnalytics(EventType.app, "Connect from app");
				this.setupPush();
			} else {
				this.analyticService.sendAnalytics(EventType.app, "Connect from browser");
			}
		});
	}


	/**
	 * Détecter la visibilité de la page à l'aide de l'Api Page Visibility
	 */

	async detectScreenVisibility() {
		let hidden, visibilityChange;
		if (typeof document.hidden !== "undefined") {
			hidden = "hidden";
			visibilityChange = "visibilitychange";
		} else if (typeof document["msHidden"] !== "undefined") {
			hidden = "msHidden";
			visibilityChange = "msvisibilitychange";
		} else if (typeof document["webkitHidden"] !== "undefined") {
			hidden = "webkitHidden";
			visibilityChange = "webkitvisibilitychange";
		}
		document.addEventListener(
			visibilityChange, (event) => {
			if (this.chatService.displayLogo()) {
				if (this.accountService.user && this.accountService.user.profileLoaded) {
					if (document[hidden]) {
								this.chatService.pageMinimized = true;
								this.router.navigateByUrl("chat");
					} else {
						if (this.chatService.pageMinimized ) {
									if (this.chatService.countNewMessages > 0) {
											this.chatService.displayNewMessageToaster(true);
											this.chatService.pageMinimized = false;
									}
							}
						}
					}
				}else{
					if (this.accountService.user && this.accountService.user.profileLoaded) {
						if (document[hidden]) {
							if (this.router.url === "/chat"){
								this.oneSingalService.activateNotifications();
							}
						} else {
							if (this.router.url === "/chat"){
								this.oneSingalService.unableToReceiveNotifications();
							}
							}
						}
				}
			},
		);
	}

	updateScreenSize() {
		if (this.chatService.displayLogo()) {
			if (!this.lowScreenResolution) {
				if (this.accountService.user && this.accountService.user.profileLoaded) {
					this.router.navigateByUrl("chat");
				}
				this.lowScreenResolution = true;
			}
			return;
		}
		if (this.chatService.countNewMessages > 0) {
			this.chatService.displayNewMessageToaster(true);
		}
		this.lowScreenResolution = false;
	}

	onDeconnexion() {
		this.darkMode.activated = false;
		this.darkMode.mode = "mode clair";
		this.accountService.logout();
	}

	detectPlatform() {
		const platform = this.platform.platforms();
		// Vérifie si la platform est ios si c'est le cas enlève les icones de redirectin du side-menu
		if (platform.includes("ios")) {
			this.iosVerif = true;
		}
	}

	/**
	 * Sert à appeler le modal abonnement
	 *
	 */
	async abonnementModal() {
		const modal = await this.modalController.create({
			component: AbonnementPage,
			cssClass: "wideModal"
		});
		return await modal.present();
	}

	joindreFichier(event): Promise<any> {
		return new Promise((resolve, reject) => {
			const fichier = event.target.files[0];
			const lecteur = new FileReader();
			lecteur.readAsDataURL(fichier);
			lecteur.onload = () => {
				if (
					lecteur.result.slice(5, 22) === "image/jpeg;base64" ||
					lecteur.result.slice(5, 21) === "image/png;base64" ||
					lecteur.result.slice(5, 21) === "image/gif;base64"
				) {
					resolve(lecteur.result);
				} else {
					reject("format de fichier invalide");
				}
			};
		});
	}

	openFileExplorer() {
		if (this.accountService.user.role !== "professeur") {
			this.selectFile.nativeElement.click();
			this.analyticService.sendAnalytics(EventType.action, "Sélection d'une photo");
		}
	}

	async profilImage(event) {
		this.joindreFichier(event)
			.then(async fichierBase64 => {
				const modal = await this.modalController.create({
					component: CropImgComponent,
					componentProps: {
						fichierBase64
					},
					enterAnimation: modalEnterByBot,
					leaveAnimation: modalLeaveByBot
				});

				modal.onDidDismiss().then(() => {
					event.target.value = "";
				});

				return await modal.present();
			})
			.catch(async err => {
				const popUpErreur = await this.alerteController.create({
					cssClass: "popUpErreur",
					header: "Erreur dans le format du fichier",
					message: "Seuls les fichiers au format *png, *jpg, *jpeg, *gif peuvent être envoyés",
					buttons: [
						{
							text: "Ok",
							role: "oK",
							cssClass: "buttonOK"
						}
					]
				});
			});
	}
}
