import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { NavController, LoadingController } from "@ionic/angular";
import { AccountService } from "src/app/services/account.service";
import { AccountImplService } from "src/app/services/account.Impl.service";
import { GlobalService } from "src/app/services/global.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { DarkModeService } from "src/app/services/dark-mode.service";
import { AnalyticService, EventType } from "src/app/services/analytic.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { OneSignalService } from "src/app/services/one-signal.service";

@Component({
	selector: "app-abonnement",
	templateUrl: "./abonnement.page.html",
	styleUrls: ["./abonnement.page.scss"]
})
export class AbonnementPage {
	url_account: string;
	ideleve_fix: number;
	token_account: any;
	urlAccountSafe: SafeResourceUrl;
	asyncResult;

	constructor(
		public navCtrl: NavController,
		public modalCtrl: ModalController,
		public accountService: AccountService,
		public accountImplService: AccountImplService,
		public globalService: GlobalService,
		public loadingController: LoadingController,
		private sanitizer: DomSanitizer,
		private analyticService: AnalyticService,
		public darkMode: DarkModeService,
		public router: Router,
		public oneSignalSerivce : OneSignalService,
		public location: Location
	) {
		this.urlChargement();

		this.accountService.getUserData().then(() => {
			this.ideleve_fix = this.accountService.user.id;
			this.token_account = this.accountService.auth.localKey;
			this.url_account =
				"https://www.planeteprofs.com/webview-dashboard/?connect_sid=" +
				this.accountService.connectSid +
				"&user_id=" +
				this.ideleve_fix +
				"&token=" +
				this.token_account +
				"&from=app";
			if (this.globalService.isIos) {
				this.url_account = this.url_account + "&platform=ios";
			}
			this.urlAccountSafe = this.iframeURL;
		});
	}

	get iframeURL() {
		return this.sanitizer.bypassSecurityTrustResourceUrl(this.url_account);
	  }

	ionViewDidEnter() {
		this.analyticService.sendAnalytics(EventType.app, "App Abonnement");
		this.oneSignalSerivce.initOneSignal();
	}

	/**
	 * Au clic sur le bouton sert à retourner sur la page précédente
	 *
	 */
	async backIframe() {
		window.history.back();
	}

	/**
	 * Sert à gérer le chargement du modal abonnement
	 *
	 */
	async urlChargement() {
		const loading = await this.loadingController.create({
			message: "Chargement en cours...",
			duration: 1000
		});
		await loading.present();
		const { role, data } = await loading.onDidDismiss();
	}

	// function to hide scrollbar in iframe's content:
	setIframeSize(iframe) {
		var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
		var iframeBody;
		if (iframeDocument) {
			iframeBody = iframeDocument.querySelector("body");
			// iframeBody.style.overflowY = 'scroll';
			iframeBody.style.overflowY = "hidden";
			iframeBody.style.overflowX = "hidden";
		}
	}
}
