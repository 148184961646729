import { ChangeDetectorRef, ComponentFactoryResolver, Injectable, QueryList, SecurityContext, ViewChild, ViewChildren } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";
import { BaseService } from "../services/base.service";
import { throwError, Subject, Subscription } from "rxjs";
import { ChatMessage } from "src/app/models/chatMessage";
import { AlertController, ToastController } from "@ionic/angular";
import { AccountService } from "./account.service";
import { Router } from "@angular/router";
import { ChatPage } from "../page/chat/chat.page";
import { NgScrollbar } from "ngx-scrollbar";
import { GlobalService } from "./global.service";
import { AnalyticService, EventType } from "./analytic.service";
import { OneSignalService } from "./one-signal.service";
declare var ng: any;

@Injectable({
	providedIn: "root"
})
export class ChatService extends BaseService {
	public countNewMessages = 0;
	public chatMessages: ChatMessage[] = [];
	public chatMessage: ChatMessage;
	public errors: any;
	public pageMinimized = false;
	public value: any = "";
	public lastMessage: any;
	public chatPage: ChatPage;
	page = 0;
	postsCompleted = true;
	SCREEN_WIDTH = 100;
	SCREEN_HEIGHT = 100;

	public newMessageReceived = new Subject();
	checkmsg: any;
	checkNewMessageErrorTimeout: NodeJS.Timeout;
	private checkNewMessageSubscription: Subscription;
	@ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;
	getMessagesErrorTimeout: NodeJS.Timeout;

	constructor(
		protected http: HttpClient,
		private sanitizer: DomSanitizer,
		public alertController: AlertController,
		public toastController: ToastController,
		public globalService: GlobalService,
		private accountService: AccountService,
		private router: Router,
		private analyticService: AnalyticService,
		private oneSignalService: OneSignalService
	) {
		super();
	}


	async displayNewMessageToaster(displayButton = false) {
		const messageText = this.countNewMessages == 1 ? "nouveau message" : "nouveaux messages";
		const firstName = this.accountService.user.firstName ? this.accountService.user.firstName + ",t" : "T";
		let toast;
		try {
			toast.dismiss();
		} catch (e) {}

		toast = await this.toastController.create({
			header: `${firstName}u as
						${this.countNewMessages} ${messageText} `,
			position: this.globalService.isCordova ? "bottom" : "top",
			duration: 3500,
			buttons: displayButton
				? [
						{
							text: "Découvrir",
							role: "cancel",
							handler: () => {
								toast.dismiss();
								if (this.router.url !== "/chat") {
									this.router.navigateByUrl("chat").then(() => {
										this.chatPage.updateScroll();
									});
									return;
								}
								this.chatPage.updateScroll();
							}
						}
				  ]
				: undefined
		});

		this.countNewMessages = 0;
		this.pageMinimized = false;
		if (!displayButton) {
			this.chatPage.updateScroll();
		}
		toast.present();
	}

	displayLogo(): boolean {
		if (window.innerHeight <= this.SCREEN_HEIGHT && window.innerWidth <= this.SCREEN_WIDTH) {
			return true;
		}

		return false;
	}

	/**
	 * Sert à gérer les messages d'erreur
	 *
	 */
	handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error("An error occurred:", error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong.
			console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
		}
		// Return an observable with a user-facing error message.
		return throwError("Il y a eu un problème; veuillez réessayer plus tard.");
	}

	/**
	 * Sert à créer un faux message pour les messages texte
	 *
	 */
	public async addMessage(datamessage: string): Promise<void> {
		return new Promise((resolve, reject) => {
			if (datamessage && typeof datamessage !== "undefined") {
				const postmsg = datamessage.replace(/"/g, "'");
				const postmsgtwo = postmsg.replace(/(\r\n|\n\r|\r|\n)/g, "<br/>");
				if (postmsgtwo === "<br/>") {
					resolve();
					return true;
				}
				// sécurise le contenu du message
				const html = this.sanitizer.sanitize(SecurityContext.HTML, postmsgtwo);
				let iconRobot: boolean;

				if (this.accountService.user.role === "eleve" || this.accountService.user.role === "professeur") {
					iconRobot = false;
				} else {
					iconRobot = true;
				}

				const message: ChatMessage = {
					title: this.accountService.user.role === "professeur" ? "Prof en Poche" : this.accountService.user.displayName,
					from: this.accountService.user.id,
					role: this.accountService.user.role,
					icon: iconRobot,
					showtitle: true,
					message: html,
					message_date: "Now",
					classes: "ToDelete",
					type: "message",
					url: "",
					url_image: "",
					heure: null
				};
				// ajoute le message dans le tableau chatMessages
				this.chatMessages = this.chatMessages.concat(message);
				this.updateScroll();
				const datapost = {
					datamessage: postmsgtwo
				};

				// sauvegarde le nouveau message
				this.http.post(this.postUrl + "?action=app_savemessage", datapost).subscribe(
					(data: any) => {
					},
					error => {
						this.handleError(error);
					}
				);
				resolve();
			}
		});
	}

	/**
	 * Sert à charger les 10 derniers message
	 *
	 */
	getMessages(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.http.get<ChatMessage[]>(this.postUrl + "?action=app_list_messages").subscribe(
				(data: any) => {

					// convertie carrousel JSON elements in Array
					data.forEach(message => {
						if (message.type === "carrousel") {
							message.message = JSON.parse(message.message);
						}

						if (message.role === "eleve") {
							message.title = `${this.accountService.user.firstName} ${this.accountService.user.lastName}`;
						}
						this.lastMessage = message._id;
						// console.log('message = ', message);
					});

					this.chatMessages = data;
					this.postsCompleted = true;
					// unsubscribe to avoid reload stacking request
					this.unsubscribeCheckNewMessage();
					this.check_new_message();
					resolve();
				},
				error => {
					if (error.status === 401) {
						// auth credential lost return to login
						console.log("erreur d'authentification !");
						this.oneSignalService.initOneSignal().then(() => {
							this.oneSignalService.deconnect();
						});
						this.errorAlert().then(() => {
							this.accountService.unloadUserData();
							this.router.navigate(["home"]);
							reject();
						});
					} else {
						reject(error);
						this.getMessagesErrorTimeout = setTimeout(() => {
							this.getMessages();
						}, 2000);
					}
				}
			);
		});
	}

	/**
	 * Sert à charger les anciens message
	 *
	 */
	loadMessages(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.http.get(`${this.postUrl}?action=app_scroll_list_message`).subscribe((data: any) => {
				if (data) {
					data.forEach(element => {
						if (element.type === "carrousel") {
							// convertie carrousel JSON elements in Array
							element.message = JSON.parse(element.message);
						}
					});
					// ajoute les anciens messages chargés au début du tableau chatMessages
					this.chatMessages = [].concat(data, this.chatMessages);
					resolve();
				}
			});
		});
	}


	/**
	 * get professor dispobibity status boolean and message
	 */
	getProfessorDisponibility(): Promise<{state: boolean , message: string}> {
		return new Promise((resolve, reject) => {
			this.http.get(`${this.postUrl}?action=app_service_open2`).subscribe((data: {state: boolean , message: string}) => {
				if (data) {
					resolve(data);
				}
			}, (error) => {
				reject ({ state : false , message : " Les professeurs sont disponibles du lundi au vendredi de 18h à 21h et de 14h à 17h le samedi hors jours fériés. Tu peux demander un prof pendant ces créneaux."});
			});
		});
	}

	async errorAlert() {
		const alert = await this.alertController.create({
			header: "Votre session a expirée",
			message: "Reconnection nécessaire",
			buttons: ["OK"],
			cssClass: "alertBox"
		});
		await alert.present();
	}

	/**
	 * Sert à afficher le dernier message saisi
	 *
	 * @param lastMessage any id du dernier message
	 */
	getNewMessage(lastMessage): Promise<void> {
		return new Promise((resolve, reject) => {
			if (this.postsCompleted) {
				this.postsCompleted = false;
				let url = this.postUrl + "?action=app_list_new_message";
				// récupère les infos du message correspontes à son id
				if (lastMessage) {
					url = this.postUrl + `?action=app_list_new_message&last_message_id=${lastMessage}`;
					this.http.get<ChatMessage[]>(url).subscribe(
						(data: any) => {
							// efface les faux messages
							this.deleteTemporaryMessages();
							// convertie carrousel JSON elements in Array
							data.forEach(message => {
								if (message.type === "carrousel") {
									message.message = JSON.parse(message.message);
								}
								if (this.displayLogo() || this.pageMinimized) {
									if (message.role === "professeur") {
										this.countNewMessages++;
									}
								}

								this.lastMessage = message._id;
							});

							// ajoute le nouveau message à la fin du tableau chatMessages
							this.chatMessages = [].concat(this.chatMessages, data);
							this.postsCompleted = true;
							console.log("nouveau message");
							this.newMessageReceived.next();

							resolve();
						},
						error => {
							if (error.status === 401) {
								// auth credential lost return to login
								console.log("erreur d'authentification !");
								this.oneSignalService.initOneSignal().then(() => {
									this.oneSignalService.deconnect();
								});

								this.errorAlert().then(() => {
									this.unsubscribeCheckNewMessage();
									this.accountService.unloadUserData();
									this.router.navigate(["home"]);
									reject();
								});
							} else {
								this.postsCompleted = true;
								reject();
							}
						}
					);
				} else {
					this.postsCompleted = true;
					this.getNewMessage(this.lastMessage);
				}
			}
		});
	}

	/**
	 * Sert à supprimer les faux messages
	 *
	 */
	deleteTemporaryMessages() {
		// remove messages with ToDelete class
		this.chatMessages = this.chatMessages.filter(item => item.classes !== "ToDelete");
	}

	/**
	 * Se relance automatiquement pour vérifier si un nouveau message est saisi,
	 * si c'est le cas envoit son id à getNewMessage() qui l'affichera
	 *
	 */
	check_new_message(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.checkNewMessageSubscription = this.http
				.get(this.postUrl + `?action=app_check_new_message&last_message_id=${this.lastMessage}`)
				.subscribe(
					async (checkmsg: any) => {
						this.checkmsg = checkmsg.trim();
						// vérifie l'apparition d'un nouveau message
						if (this.checkmsg === "true") {
							await this.getNewMessage(this.lastMessage);
						}
						// relance la fonction afin de la faire tourner en boucle
						this.check_new_message();
						resolve();
					},
					error => {
						console.log(error);
						if (error.status === 401) {
							// auth credential lost return to login
							console.log("erreur d'authentification !");
							this.errorAlert().then(() => {
								this.accountService.unloadUserData();
								this.unsubscribeCheckNewMessage();
								this.router.navigate(["home"]);
								reject();
							});
						} else {
							// relance la fonction afin de la faire tourner en boucle
							this.checkNewMessageErrorTimeout = setTimeout(() => {
								this.check_new_message();
							}, 2000);
							reject();
						}
					}
				);
		});
	}

	/**
	 * Unsubscribes check new messageto avoid stacking request
	 */
	unsubscribeCheckNewMessage() {
		if (this.checkNewMessageErrorTimeout) {
			clearTimeout(this.checkNewMessageErrorTimeout);
		}
		if (this.getMessagesErrorTimeout) {
			clearTimeout(this.getMessagesErrorTimeout);
		}
		if (this.checkNewMessageSubscription) {
			this.checkNewMessageSubscription.unsubscribe();
		}
	}

	/**
	 * Au click sur Demander un  prof,  poste un faux message et fait une requête au serveur
	 *
	 */
	askProf() {
		const post: ChatMessage = {
			title: this.accountService.user.role === "professeur" ? "Prof en Poche" : this.accountService.user.displayName,
			from: this.accountService.user.id,
			role: localStorage.getItem("role"),
			showtitle: true,
			message: " Demander un prof 🕵",
			message_date: "Now",
			classes: "ToDelete",
			icon: null,
			type: "",
			url: "",
			url_image: "",
			heure: null
		};

		this.chatMessages = this.chatMessages.concat(post);
		this.updateScroll();

		const datapost = {
			datamessage: "Demander un prof 🕵",
			payload: "dialogflow"
		};

		this.http.post(this.postUrl + "?action=app_savemessage", datapost).subscribe(data => {
			if (this.router.url !== "/chat") {
				this.router.navigateByUrl("/chat");
			}
			this.analyticService.sendAnalytics(EventType.action, "Demande de prof APP");
		});
	}

	updateScroll() {
		// hack don't find a more beautiful way to get a reference on ChatPage
		if (!this.chatPage) {
			const scrollItem = document.querySelector("app-chat");
			if (scrollItem) {
				this.chatPage = ng.getComponent(scrollItem);
			}
		}
		if (this.chatPage) {
			setTimeout(() => {
				this.chatPage.updateScroll();
			}, 100);
		}
	}
}
