import { Component, OnInit, Input } from '@angular/core';
import { NavController, LoadingController,  } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-bigpicture',
  templateUrl: './bigpicture.page.html',
  styleUrls: ['./bigpicture.page.scss'],
})
export class BigpicturePage implements OnInit {


  @Input("idImage") idImage;
  @Input("index") index;
  @Input("data_gallery") data_gallery;

  public testindex 
  public stopPrevious = false;
  public stopNext = false;

  constructor(public navCtrl: NavController,
              public navParams: NavParams,
              public modalCtrl: ModalController,
              public loadingController: LoadingController,
              ) {
                this.urlChargement();
               }

  ngOnInit() {
    console.log(this.index)
    if(this.index === 0){
      this.stopPrevious = true;
    }if(this.index+1 === this.data_gallery.length){
      this.stopNext = true;
    }
  }

 

  async urlChargement() {
    const loading = await this.loadingController.create({
      message:'Chargement en cours...',
      duration: 10
    });
    await loading.present();
    const { role, data } = await loading.onDidDismiss();
  }


  public previousImage(){
    for(let i=0; i < this.data_gallery.length; i++){
      if(this.idImage[i] == this.idImage[this.index]){
        this.index = this.index - 1;
        this.stopNext = false;
        if(this.index === 0){
          this.stopPrevious = true;
        }
      }break
    }
  }


  public nextImage(){
    for(let i=0; i < this.data_gallery.length; i++){
      if(this.idImage[i] == this.idImage[this.index]){
        this.index = this.index + 1;
        this.stopPrevious = false;
        if(this.index+1 === this.data_gallery.length){
          this.stopNext = true;
        }
      }break
    }
  }


  public closeModal() {
    this.modalCtrl.dismiss({
    'dismissed': true
  });
}
}
