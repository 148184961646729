import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseService } from "./base.service";
import { AccountService } from "./account.service";

export enum EventType {
	action = "user_id",
	app = "login"
}
@Injectable({
	providedIn: "root"
})


export class AnalyticService extends BaseService {
	constructor(private http: HttpClient, private accountService: AccountService) {
		super();
	}

	/**
	 * Envoi des évènements d'analytique
	 */
	sendAnalytics(userEvent : string, event: string, link = null) {
		if(this.accountService.user.id) {
			const userEventTargetted = this.currentUserEvent(userEvent);
			let data: any = {...userEventTargetted, event};
			if (link) {
				data.params = {link};
			}
			this.http.post<any>(this.postUrl + "?action=analytics_events", data).subscribe((value) => {
			});
		}
	}

	/**
	 * Définir le type d'event
	 */

	currentUserEvent(userEvent) {
		if (userEvent === EventType.app) {
			return {[userEvent] : this.accountService.user.email};
		} else if (EventType.action) {
			return {[userEvent] : this.accountService.user.id};
		}
	}

	onPause() {
		console.log("App Pause");
		this.sendAnalytics(EventType.app, "App Pause");
	}

	onReady() {
		console.log("App Device ready");
		this.sendAnalytics(EventType.app, "App Device ready");
	}

	onResume() {
		console.log("App Resume");
		this.sendAnalytics(EventType.app, "App Resume");
	}

}
