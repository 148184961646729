import { Injectable, Injector } from "@angular/core";
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { AccountImplService } from "./account.Impl.service";
import { retry, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';


@Injectable({
	providedIn: "root"
})
export class TokenInterceptorService implements HttpInterceptor {
	constructor(public accountService: AccountImplService) {}
	intercept(req: HttpRequest<any>, next: HttpHandler){ 

			const connectSid = this.accountService.connectSid;
			const tokens = JSON.parse(localStorage.getItem('tokens'));
			let cookies = '';
			for(const k in tokens){
				if(tokens.hasOwnProperty(k)){
					cookies += k + '=' + tokens[k] +'; ';
				}
			}
			let newHeaders = req.headers.set("CONNECTSID", connectSid);
			newHeaders = newHeaders.set("CUSTOMCOOKIES", cookies);
			//tokenReq est = à un clone de la requête http
			const tokenReq = req.clone({
				//mis en place du token
				headers: newHeaders,
				withCredentials: true
			});
			// console.error(tokenReq.headers.keys());

			return next.handle(tokenReq)
			
		}
			
	}
		
	
		
	

// connectSid
