import { Injectable } from "@angular/core";
import { User } from "../models/user";
import { Observable } from "rxjs";
import { UserCredential } from '../models/user-credential';
import { ClassesService } from "./classes.service";
import { GlobalService } from "./global.service";
import { AnalyticService } from "./analytic.service";
import { OneSignalService } from "./one-signal.service";

@Injectable({
	providedIn: "root"
})


export abstract class AccountService {
	abstract user: User;
	abstract auth: UserCredential;
	abstract skilleosUser;
	abstract connectSid;
	abstract listeCours;
	abstract dataPost: any;
	abstract classesService: ClassesService;
	abstract listeCoursClassID: number;
	abstract listeCoursClassName: string;
	abstract storeLocalStorValue(key: string, value);
	abstract getLocalStorValue(key: any);
	abstract removeLocalStorKey(key);
	abstract checkUserPermission(value?: string): boolean;
	abstract checkUserConnected(): boolean;
	abstract sendEmail(): Observable<boolean>;
	abstract getUserData();
	abstract loadUserData(data: any);
	abstract unloadUserData();
	abstract getListeCours();
	abstract logout();
	abstract setAnalyticService(analyticService: AnalyticService);
	abstract setOneSignalService(oneSignalService: OneSignalService);
	abstract setGlobalService(globalService: GlobalService);
}
