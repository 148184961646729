import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AppComponent } from "./app.component";
import { ProfileResolveService } from "./services/profile-resolve.service";
import { SsoService } from "./services/sso.service";

const routes: Routes = [
	{
		path: "",
		redirectTo: "home",
    pathMatch: "full",
	},
    { 	path: "home",
       loadChildren: "./page/home/home.module#HomePageModule",
    },
	{
		path: 'chat', 
    loadChildren: () => import('./page/chat/chat.page.module').then(m => m.ChatPageModule),
    canActivate: [
      SsoService
    ]
	},
  {
    path: 'signup',
    loadChildren: () => import('./page/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'camera',
    loadChildren: () => import('./page/camera/camera.module').then( m => m.CameraPageModule)
  },
  {
	path: 'profile',
  loadChildren: () => import('./page/profile/profile.module').then( m => m.ProfilePageModule),
  resolve: {
    userData : ProfileResolveService
  }
  },
  {
    path: 'cours',
    loadChildren: () => import('./page/modal/cours/cours.module').then( m => m.CoursPageModule)
  },
  {
    path: 'politique-confidentialite',
    loadChildren: () => import('./page/politique-confidentialite/politique-confidentialite.module').then( m => m.PolitiqueConfidentialitePageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./page/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
  path: "avis",
    component: AppComponent,
  },
  {
    path: 'gallery',
    loadChildren: () => import('./page/gallery/gallery.module').then( m => m.GalleryPageModule)
  },
  {
    path: 'qcm',
    loadChildren: () => import('./page/qcm/qcm.module').then( m => m.QcmPageModule)
  }, 
  {
    path: 'messages',
    loadChildren: () => import('./page/messages/messages.module').then( m => m.MessagesPageModule)
  },
  {
    path: 'abonnement',
    loadChildren: () => import('./page/modal/abonnement/abonnement.module').then( m => m.AbonnementPageModule)
  }


];
@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
