import { Injectable, NgZone } from "@angular/core";
import { LoadingController, Platform } from "@ionic/angular";
import { File } from "@ionic-native/file/ngx";
import { Observable } from "rxjs";
import { Media, MediaObject, MEDIA_STATUS } from "@ionic-native/media/ngx";
import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";

declare var MediaRecorder: any;
declare var window: { MediaRecorderPolyfill: any };
declare var Audio: any;

@Injectable({
	providedIn: "root"
})
export class RecordService extends BaseService {
	// record android ios
	cdvPath: string;
	audioFile: MediaObject;
	audioFileStatus: Observable<MEDIA_STATUS>;
	mediaTimer: any;
	ampSize = 200;
	stream: MediaStream;

	// record browser
	mediaRecorderBrowser: any;
	chunks = [];
	browserAudiofilesUrl: string;

	// flag
	public record = false;
	pressRecord = false;
	end = false;
	endTimer = false;
	isBrowser: boolean;
	canceledRecord = false;

	currentRecord: { recordFile: Blob; fileName: string };

	constructor(
		public platform: Platform,
		private file: File,
		private zone: NgZone,
		private media: Media,
		private http: HttpClient,
		private loadingController: LoadingController
	) {
		super();
		this.currentRecord = { recordFile: null, fileName: null };
	}

	/**
	 * Gets micro access credential : web api
	 */
	getMediaAccess(): Promise<MediaStream> {
		if (this.isBrowser) {
			if (navigator.getUserMedia) {
				return new Promise<MediaStream>((resolveP, reject) => {
					navigator.getUserMedia(
						{ audio: true, video: false },
						stream => {
							resolveP(stream);
						},
						err => {
							reject(err);
						}
					);
				});
			} else if (navigator.mediaDevices) {
				return navigator.mediaDevices.getUserMedia({ audio: true, video: false });
			}
		}
	}

	/**
	 * Init record for web api
	 */
	initRecord() {
		// We use polyfill
		if (window.MediaRecorderPolyfill) {
			this.mediaRecorderBrowser = new MediaRecorder(this.stream);
			this.mediaRecorderBrowser.addEventListener("stop", e => {});
			// only send one event on dataavailable when record is finish
			this.mediaRecorderBrowser.addEventListener("dataavailable", e => {
				this.zone.run(() => {
					this.currentRecord.recordFile = e.data;
					this.currentRecord.fileName = this.createGuid();
					this.record = false;
					const audioURL = URL.createObjectURL(this.currentRecord.recordFile);
					this.browserAudiofilesUrl = audioURL;
					this.sendRecord();
					console.log("recorder stopped");
				});
			});
		} else {
			// whithout polyfill
			const options = {
				mimeType: "video/webm;codecs=h264"
			};
			this.mediaRecorderBrowser = new MediaRecorder(this.stream);
			// on stop we generate the file
			this.mediaRecorderBrowser.onstop = e => {
				this.zone.run(() => {
					console.log("data available after MediaRecorder.stop() called.");
					this.currentRecord.recordFile = new Blob(this.chunks, { type: "video/webm" });
					this.currentRecord.fileName = this.createGuid();
					this.record = false;
					this.chunks = [];
					const audioURL = URL.createObjectURL(this.currentRecord.recordFile);
					this.browserAudiofilesUrl = audioURL;
					this.sendRecord();
					console.log("recorder stopped");
				});
			};
			// populate chunk array when record
			this.mediaRecorderBrowser.ondataavailable = e => {
				this.chunks.push(e.data);
			};
		}
	}

	/**
	 * Start record with js api for browser or cordova api for mobile device
	 */
	startRecord(): Promise<MediaStream> {
		return new Promise<MediaStream>(async (resolveP, reject) => {
			this.isBrowser = navigator.getUserMedia || navigator.mediaDevices ? true : false;
			if (!this.isBrowser) {
				let tempDirectory: string;
				let filetype: string;
				tempDirectory = this.platform.is("ios") ? this.file.tempDirectory : this.file.cacheDirectory;
				filetype = this.platform.is("ios") ? "MathiaTempRecord.wav" : "MathiaTempRecord.aac";
				const filePath = await this.file.createFile(tempDirectory, filetype, true);
				if (this.audioFile) {
					this.audioFile.release();
				}
				this.audioFile = this.media.create(tempDirectory.replace(/^file:\/\//, "") + filetype);
				this.pressRecord = true;
				// this.audioFileStatus = this.audioFile.onStatusUpdate;

				this.audioFile.onStatusUpdate.subscribe(status => {
					console.log(status);
					if (this.pressRecord && status === 2) {
						console.log("setTimeOutStart");
						this.record = true;
						console.log("recording starting");
						this.pressRecord = false;
					}
				}); // fires when file status changes

				this.audioFile.onSuccess.subscribe(() => {
					console.log("Action is successful");
				});

				this.audioFile.onError.subscribe(error => {
					console.log("Error!", error);
					this.pressRecord = false;
				});
				// Todo verify when audio right are prompt
				this.audioFile.startRecord();
				this.cdvPath = filePath.toInternalURL();
				resolveP(null);
			} else {
				// if this.mediaRecorderBrowser is create we already have the right else the browser prompt a demand or create one
				if (this.mediaRecorderBrowser) {
					this.record = true;
					this.mediaRecorderBrowser.start();
					resolveP(this.stream);
				} else {
					this.getMediaAccess()
						.then(stream => {
							this.stream = stream;
							this.initRecord();
							this.record = true;
							this.mediaRecorderBrowser.start();
							resolveP(stream);
						})
						.catch(err => {
							alert("Error capturing audio browser access." + err.name);
							reject(err);
						});
				}
			}
		});
	}

	/**
	 * Stops record and play tts and record
	 */
	stopRecord() {
		if (!this.isBrowser) {
			this.endRecord();
		} else {
			this.mediaRecorderBrowser.stop();
		}
	}

	/**
	 * Stop record and flag to not send record
	 */
	cancelRecord() {
		this.canceledRecord = true;
		this.stopRecord();
	}

	/**
	 * End the record for cordova and store the file
	 */
	endRecord() {
		this.audioFile.stopRecord();
		this.record = false;
		console.log("recording stopped");
		clearInterval(this.mediaTimer);
		this.storeAudioFile().then(() => {
			this.sendRecord();
			if (this.platform.is("ios")) {
				this.audioFile.release();
			}
		});
	}

	/**
	 * Stores audio file for ios android into currentRecord
	 */
	storeAudioFile(): Promise<any> {
		return this.makeFileIntoBlob(this.cdvPath).then(data => {
			data.blobName = this.createGuid();
			this.currentRecord.recordFile = data.blob;
			this.currentRecord.fileName = this.createGuid();
		});
	}

	/**
	 * Convert a file into blob
	 * @param filePath string
	 * @returns Promise <{ blob: Blob, blobName: string}>
	 */
	makeFileIntoBlob(filePath: string): Promise<{ blob: Blob; blobName: string }> {
		return new Promise((resolve, reject) => {
			let fileName,
				fileExtension = "";
			this.file
				.resolveLocalFilesystemUrl(filePath)
				.then(fileEntry => {
					const { name, nativeURL } = fileEntry;
					// get the path..
					const path = nativeURL.substring(0, nativeURL.lastIndexOf("/"));
					fileName = name;
					// if you already know the file extension, just assign it to           // variable below
					fileExtension = fileName.match(/\.[A-z0-9]+$/i)[0].slice(1);
					// we are provided the name, so now read the file into a buffer
					return this.file.readAsArrayBuffer(path, name);
				})
				.then(buffer => {
					// get the buffer and make a blob to be saved
					const medBlob: Blob = new Blob([buffer], {
						type: `audio/${fileExtension}`
					});

					// pass back blob and the name of the file for saving
					resolve({ blob: medBlob, blobName: null });
				})
				.catch(e => reject(e));
		});
	}

	async sendRecord() {
		if (!this.canceledRecord) {
			const loading = await this.loadingController.create({
				message: "Envoi du cours ..."
			});
			await loading.present();
			const formData = new FormData();
			formData.append("action", "app_upload_audio_3");
			formData.append("blob", this.currentRecord.recordFile, this.currentRecord.fileName);
			this.http.post(this.postUrl, formData).subscribe(
				(data: any) => {
					loading.dismiss();
				},
				error => {
					this.handleError(error);
					loading.dismiss();
				}
			);
		}
		this.canceledRecord = false;
	}

	/**
	 * Creates guid for filename
	 * @returns guid string
	 */
	createGuid(): string {
		// tslint:disable-next-line: only-arrow-functions
		return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
			// tslint:disable-next-line: no-bitwise
			const r = (Math.random() * 16) | 0,
				// tslint:disable-next-line: no-bitwise
				v = c === "x" ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}
}
