import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { FilterPipe } from './chat.pipe';
import { TrustedPipe } from './chat.pipe';
import { SafePipe } from './chat.pipe';
@NgModule({

    declarations: [FilterPipe, TrustedPipe, SafePipe],
    imports: [
        CommonModule
    ],
    exports: [
        FilterPipe,
        TrustedPipe,
        SafePipe
    ]
})
export class PipeModule {}