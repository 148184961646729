import { User } from "../user";

export class LrsUtils {
    static BASE_URL = "https://vps787669.ovh.net";
	static AUTHORISATION = "NGExZjZjY2FhZjc4Njk2YmFkYTBjMmRhMTA4ODhkMWM2YzI0ODUzMzo2MjlhYTA0MDY2YmM0NzM0MjQyM2UxNWU0NTk5ZDkxMTFiYjkyZDEz";
	static currentUser: User;
	static idCours;

	static changeVerb(statement,verb ) {
		for (const currentStatement in statement) {
			if (statement.hasOwnProperty("verb") && currentStatement === "verb") {
				statement[currentStatement] = verb;
			}
		  }
		return statement;
	}
}
