import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileResolveService implements Resolve<any> {
  constructor(private accountService: AccountService) {}


  resolve(): Promise<any> {
    return new Promise((resolve,reject) => {
      this.accountService.getUserData().then(data => {
        console.log("user", this.accountService.user)
        resolve(true);
      });
    });
  }
}
