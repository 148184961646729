import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class EventsService {
	events: { string: Subject<any> }[] = new Array();

	publish(eventName: string, data?: any) {
		if (this.events[eventName]) {
			console.log(eventName + " - Publish");
			this.events[eventName].next(data);
		}
	}

	subscribe(eventName: string, arg1: () => void) {
		if (!this.events[eventName]) {
			this.events[eventName] = new Subject<any>();
		}

		this.events[eventName].subscribe({
			next(response) {
				arg1;
				console.log(eventName + " - Receive ");
			},
			error(err) {
				console.error(eventName + " - Event Error: " + err);
			},
			complete() {
				console.log(eventName + " - Completed");
			}
		});
		console.log(eventName + " - Subscribe");
	}

	unsubscribe(eventName: string) {
		if (this.events[eventName]) {
			this.events[eventName].unsubscribe();
		}
	}

	constructor() {}
}
