import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DarkModeService {
	public activated = false;

  constructor() { }
  public mode:string="mode clair";

	swapNight() {
		if (this.activated){
			this.mode="mode nuit";
			// this.activated = true;
			document.body.classList.add("dark");
			document.body.classList.add(".chat-bubble.left-dark");
			// change darkmode bg color here:
			// #666666
			// #302485
			document.documentElement.style.setProperty("--contentBgColor", "black");
		}else{
			// this.activated = false;
			this.mode="mode clair";
			document.body.classList.remove("dark");
			document.body.classList.remove(".chat-bubble.left-dark");
			document.documentElement.style.setProperty("--contentBgColor", "#F2F2F2");
		}
	}
}
