import { Component } from '@angular/core';
import { NavController, LoadingController,  } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-politique-confidentialite',
  templateUrl: './politique-confidentialite.page.html',
  styleUrls: ['./politique-confidentialite.page.scss'],
})
export class PolitiqueConfidentialitePage {

  loadUrlModal = "https:\/\/profenpoche.com\/politique-confidentialite\/";
  asyncResult;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public loadingController: LoadingController) { 
      this.urlChargement();

    }


    async urlChargement() {
      const loading = await this.loadingController.create({
        message:'Chargement en cours...',
        duration: 2000
      });
      await loading.present();
      const { role, data } = await loading.onDidDismiss();
    }
  
  
  
    public closeModal() {
      this.modalCtrl.dismiss({
      'dismissed': true
    });
}

}
