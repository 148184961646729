import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';


@Pipe({ name: 'filterUnique' })

  export class FilterPipe implements PipeTransform {

    transform(message: any): string {

      // Remove the duplicate elements
      let uniqueArray = message.filter(function (el, index, array) { 
        return array.indexOf (el) == index;
      });

      return uniqueArray;
    }
  }

@Pipe({ name: 'url_trusted' })

  export class TrustedPipe implements PipeTransform {

    transform(message: any): string {
      let uniqueArray = message.filter(function (el, index, array) { 
        return array.indexOf (el) == index;
      });
      
  
      return ;
    }
  }

@Pipe({ name: 'safe' })

export class SafePipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) { }
  transform(loadUrlModal) {

    console.log(this.sanitizer.bypassSecurityTrustResourceUrl(loadUrlModal));

    return this.sanitizer.bypassSecurityTrustResourceUrl(loadUrlModal);
  }
}