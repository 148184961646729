import { ChatService } from "src/app/services/chat.service";
import { ChatMessage } from "src/app/models/chatMessage";
import { AfterViewInit, ElementRef, Input, ViewChild, ViewChildren } from "@angular/core";
import { ModalController, LoadingController, Platform } from "@ionic/angular";
import { HttpClient } from "@angular/common/http";
import { BaseService } from "src/app/services/base.service";
import { Component, OnInit } from "@angular/core";
import { AccountService } from "src/app/services/account.service";
import { DarkModeService } from "src/app/services/dark-mode.service";
import { ClassesService } from "src/app/services/classes.service";
import { LrsUtils } from "src/app/models/lrs/lrs-utils";
import { GlobalService } from "src/app/services/global.service";
import { Router } from "@angular/router";
@Component({
	selector: "app-revision",
	templateUrl: "./revision.page.html",
	styleUrls: ["./revision.page.scss"]
})
export class RevisionPage extends BaseService implements AfterViewInit, OnInit {
	@Input() inMenu: boolean;
	classes: {};
	constructor(
		private http: HttpClient,
		private modalCtrl: ModalController,
		private chatService: ChatService,
		private loadingController: LoadingController,
		public accountService: AccountService,
		public platform: Platform,
		public globalService: GlobalService,
		public darkMode: DarkModeService,
		public classesService: ClassesService,
		public router: Router
	) {
		super();
	}

	@ViewChildren("matieres") listeDivMatieres;
	@ViewChild("sectionCours") sectionCours: ElementRef;
	public listeCours = new Array();
	public matiereOuverte: string;

	ngAfterViewInit(): void {}

	async ngOnInit() {
		this.getClassesListe();
	}

	// try to fix overflow problem:
	// changeSectionOnOpen(open) {
	// 	console.log("changeSectionOnOpen = " + open);
	// 	if (open) {
	// 		this.sectionCours.nativeElement.setStyle({
	// 			position: 'fixed!important'
	// 		});
	// 		// ,
	// 		// top: '18vh'
			
	// 	} else {
	// 		this.sectionCours.nativeElement.setStyle({
	// 			position: 'absolute!important'
	// 		});
	// 	}
	// }

	getClassesListe() {
		// récupérer la liste des classes (avec ID) puis peupler le select avec
		this.classesService.getClassesAsync().then(
			data => {
				this.classes = data;
			},
			error => {
				console.log(error);
			}
		);
	}

	/**
	 * Fais une requête au serveur pour obtenir les cours et en extrait un tableau affichable
	 */

	getListeCours(event = null): void {
		// formData to filter list from server:
		// console.log("getListeCours");
		this.listeCours = new Array();
		this.accountService.getListeCours();
	}

	/**
	 * récupére l'id du cours puis envoi une requête au serveur pour obtenir ce cours, ajoutes aussi unfaux messages au tchat
	 *
	 * @param {number} idCours l'id du cours à rechercher
	 */

	rechercherCours(idCours) {
		this.chargement();

		const datapost = {
			id_cours: idCours
		};

		const message: ChatMessage = {
			title: this.accountService.user.role == "professeur" ? "Prof en Poche" : this.accountService.user.displayName,
			from: this.accountService.user.id,
			role: this.accountService.user.role,
			icon: null,
			showtitle: true,
			message: "Demande de cours",
			message_date: "Now",
			classes: "ToDelete",
			url: "",
			url_image: "",
			type: "",
			heure: null
		};

		this.chatService.chatMessages = this.chatService.chatMessages.concat(message);


		this.http.post(this.postUrl + "?action=app_send_course", datapost).subscribe(
			data => {
				if (this.router.url !== "/chat") {
					this.router.navigateByUrl("/chat");
				}
				this.loadingController.dismiss();
				this.dismiss();
			},
			error => {
				this.loadingController.dismiss();
				this.chatService.handleError(error);
			}
		);
	}

	/**
	 * Gére les animation accordéon du menu quelle matier doit s'ouvrir et lesquelles doivent fermer
	 *
	 * @param {string} nomMatiere
	 */

	scrollDiv(nomMatiere) {
		const divNomMatiere = this.listeDivMatieres.find(divMatiere => divMatiere.nativeElement.id === nomMatiere.key);
		if (nomMatiere.value.open) {
			setTimeout(() => {
				divNomMatiere.nativeElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
			}, 100);
		}
	}

	/**
	 * quitte le modal
	 */

	dismiss() {
		this.modalCtrl.getTop().then((overlay) => {
			if (overlay) {
				this.modalCtrl.dismiss({
					dismissed: true
				});
			}
		});
	}

	/**
	 * fait apparaître le loading
	 */

	async chargement() {
		const loading = await this.loadingController.create({
			message: "Envoi du cours ..."
		});
		await loading.present();
	}
}
