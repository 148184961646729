import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SafariService {

  constructor(private router:Router,private platform:Platform) { }

  onSafari(name) :boolean{
    if(this.platform.is('desktop') || this.platform.is('mobileweb')){
    var ba = ["Chrome","Firefox","Safari","Opera","MSIE","Trident","Edge"];
    var b, ua = navigator.userAgent;
    for(var i=0; i < ba.length; i++){
        if(ua.indexOf(ba[i]) > -1 ){

          b = ba[i];
            break;
        }
    }
    
    if(b == name){
      
     return true 
    }
    
    return false
  }
  }
}
