import { JoindreFichierPageModule } from "./page/modal/joindre-fichier/joindre-fichier.module";
import { RevisionPageModule } from "./page/modal/revision/revision.module";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { CameraPreview } from "@ionic-native/camera-preview/ngx";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouteReuseStrategy } from "@angular/router";
import { File } from "@ionic-native/file/ngx";
import { IonicModule, IonicRouteStrategy, Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Media, MediaObject, MEDIA_STATUS } from "@ionic-native/media/ngx";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AccountService } from "./services/account.service";
import { AccountImplService } from "./services/account.Impl.service";
import { TextToSpeech } from "@ionic-native/text-to-speech/ngx";
import { FormsModule } from "@angular/forms";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { AnalyticService } from "./services/analytic.service";
import { TokenInterceptorService } from "./services/token-interceptor.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AutoCompleteModule } from "ionic4-auto-complete";
import { RgpdPageModule } from "./page/rgpd/rgpd.module";
import { Camera } from "@ionic-native/camera/ngx";
import { CoursPageModule } from "./page/modal/cours/cours.module";
import { PolitiqueConfidentialitePageModule } from "./page/politique-confidentialite/politique-confidentialite.module";
import { EmailComposer } from "@ionic-native/email-composer/ngx";
import { BigpicturePageModule } from "./page/modal/bigpicture/bigpicture.module";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { CalculatricePageModule } from "./page/modal/calculatrice/calculatrice.module";
import { KatexModule } from "ng-katex";

import { MessagesPageModule } from "./page/messages/messages.module";
import { OneSignal } from "@ionic-native/onesignal/ngx";
import { OneSignalService } from "./services/one-signal.service";
import { RevisionPage } from "./page/modal/revision/revision.page";
import { RecordService } from "./services/record.service";
import { CropImgComponent } from "./crop-img/crop-img.component";
// import { ImageCropperModule } from "ngx-image-cropper";
import { LrsService } from "./services/lrs.service";
import { ChatService } from "./services/chat.service";
import { GlobalService } from "./services/global.service";
import { Keyboard } from '@ionic-native/keyboard/ngx';

@NgModule({
	declarations: [AppComponent, RevisionPage, CropImgComponent],
	entryComponents: [RevisionPage, CropImgComponent],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		// ImageCropperModule,
		HttpClientModule,
		IonicModule.forRoot({ animated: false }),
		AppRoutingModule,
		FormsModule,
		AutoCompleteModule,
		RgpdPageModule,
		CoursPageModule,
		RevisionPageModule,
		PolitiqueConfidentialitePageModule,
		JoindreFichierPageModule,
		BigpicturePageModule,
		CalculatricePageModule,
		MessagesPageModule,
		KatexModule
	],

	providers: [
		AnalyticService,
		ChatService,
		LrsService,
		Camera,
		File,
		Media,
		Platform,
		StatusBar,
		SplashScreen,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: AccountService, useClass: AccountImplService },
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
		TextToSpeech,
		ScreenOrientation,
		CameraPreview,
		AndroidPermissions,
		EmailComposer,
		Geolocation,
		OneSignalService,
		OneSignal,
		ChatService,
		GlobalService,
		Keyboard
	],

	bootstrap: [AppComponent],
})
export class AppModule {}
