import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { AccountService } from "./account.service";
import { Observable } from "rxjs";
import { LrsUtils } from "../models/lrs/lrs-utils";
import { QCM } from "../models/lrs/QCM";

export enum LrsType {
	qcm = "QCM",
}
@Injectable({
	providedIn: "root"
})
export class LrsService {
	public responseTimeFinished: number;
	public currentActivityFinished = 0;
	public idsession: string;
	public experienceApiHeader;
	public statement : any;

	private qcm;
	verbs: any;

	constructor(
    private http: HttpClient,
    private accountService : AccountService
	) {
		this.getXExpVersion(this.http).subscribe(data => {
			this.experienceApiHeader = data["version"][0];
		});

		this.getAllVerbs().subscribe((currentVerb : any) => {
			this.verbs = currentVerb.verbs;
		});
	}

	/**
	 * Obtenir la version de LRS
	 */

	public getXExpVersion(http) {
		return http.get(`${LrsUtils.BASE_URL}/data/xAPI/about`);
	  }


	public createAsyncStore(dataInfo): Promise<any> {
		return new Promise((resolve, reject) => {
			const postBody = {
				title: dataInfo,
				organisation: "6001c0d89c05272403059b5c"
            };

			this.httpRequestParams("post", `${LrsUtils.BASE_URL}/api/v2/lrs`, postBody).subscribe(lastData => {
				if (lastData) {
					this.httpRequestParams("get", `${LrsUtils.BASE_URL}/api/v2/client?query={"lrs_id": "${lastData["_id"]}"}`).subscribe(
						data => {
							const basic_key = data[0].api.basic_key;
							const basic_secret = data[0].api.basic_secret;
							const encodeBasicAuth = btoa(`${basic_key}:${basic_secret}`);
							resolve({organisation : lastData["_id"], encodeBasicAuth});
						}
					);
				}
			});
		});
	}

	/**
	 * Obtenir la liste de tous les verbs
	 */

	getAllVerbs() {
		return this.http.get("/assets/json/verbs.json");
	}

	/**
	 * Requête vers le serveur
	 */

    public httpRequestParams(method, url, body?): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				Authorization:  `BASIC ${LrsUtils.AUTHORISATION}`,
				"Content-Type": "application/json",
				"X-Experience-API-Version": this.experienceApiHeader
			})
		};

		if (method == "post") {
			return this.http.post(url, body, httpOptions);
		} else {
			return this.http.get(url, httpOptions);
		}
  }

   public getAllStores(codeclasse) {
		return this.httpRequestParams("get", `${LrsUtils.BASE_URL}/api/v2/lrs?query={"title": ${codeclasse}}`);
   }

   getBasicAuthClientAsync(): Promise<void> {
	return new Promise((resolve, reject) => {
		const lrsId = this.accountService.getLocalStorValue("lrs_id");
		let encodeBasicAuth;
		this.httpRequestParams("get", `${LrsUtils.BASE_URL}/api/v2/client?query={"lrs_id": "${lrsId}"}`).subscribe(data => {
			const basic_key = data[0].api.basic_key;
			const basic_secret = data[0].api.basic_secret;
			encodeBasicAuth = btoa(`${basic_key}:${basic_secret}`);
			this.accountService.storeLocalStorValue("clientBasicAuth_PEP", encodeBasicAuth);
			resolve();
		});
	});
	}

   createStatement(typeActivity) {
			const encodedClientBasicAuth = this.accountService.getLocalStorValue("clientBasicAuth_PEP");
			if (!encodedClientBasicAuth) {
				this.getAllStores(0).subscribe(
					data => {
						if (data.length === 0) {
							this.createAsyncStore(0).then((created) => {
								this.initStatement(typeActivity);
							});
						} else {
							this.accountService.storeLocalStorValue("lrs_id", data[0]["_id"]);
							this.getBasicAuthClientAsync().then((data) => {
								this.initStatement(typeActivity);
							});
						}
					}
				);
				return;
		}
	  this.initStatement(typeActivity);
	}

	initStatement(typeActivity) {
		this.model_globalInformations();
		LrsUtils.changeVerb(this.statement, "completed");
		this.getStatement(typeActivity);
		// this.httpRequestParams("post", `${LrsUtils.BASE_URL}/data/xAPI/statements`, statement).subscribe(data => {
		// 	console.log("send", data);
		// });
	}

	// changeVerb(verb) {
	// 	this.model_globalInformations();

	// 	for (const currentStatement in this.statement) {
	// 		if(this.statement.hasOwnProperty("verb") && currentStatement === "verb"){
	// 			this.statement[currentStatement] = verb;
	// 		}
	// 	  }
	// }

	getStatement(activity) {
		if (activity === LrsType.qcm) {
			this.qcm = new QCM(this.statement, 1, 1);
			this.qcm.start();
		}
	}

	public model_globalInformations() {
		this.statement = {
			actor: {
				account: {
					homePage: "https://xapi.profenpoche",
					name: this.accountService.user.id
				}
			},
			verb : "initialized",
			object : {
				definition : {
					name : {
						"fr-FR": ""
					},
					description : {
						"fr-FR": ""
					},
					type : ""
				},
				objectType : {}
			},
			context: {
					extensions: {
						id_classe: this.accountService.user.classID,
						nom_classe: this.accountService.user.className,
					}
			}
		};
	}
}
