import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { JoindreFichierPage } from './joindre-fichier.page';

const routes: Routes = [
  {
    path: '',
    component: JoindreFichierPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class JoindreFichierPageRoutingModule {}
