import { Injectable } from "@angular/core";
import { AccountService } from "./account.service";
import { BaseService } from "./base.service";
import { ToastController } from "@ionic/angular";
import { GlobalService } from "./global.service";
import { OneSignal } from "@ionic-native/onesignal/ngx";
import {SW} from "../../assets/oneSignalWeb/OneSignalSDKWorker.js";
import { Route } from "@angular/compiler/src/core";
import { ActivatedRoute, Router } from "@angular/router";
let OneSignalDesc;
const url = "";
/**
 * Ce service ne serve QUE SUR NAVIGATEUR (car on utilise un plugin sur tablettes et mobile)
 * Il permet de mettre en place les web push notifications (l'élève reçoit alors les messages de l'application
 * sur son navigateur) : elles ne doivent être reçues QUE :
 * - si l'utilisateur y a explicitement consenti (bouton toggle sur la page profil)
 * - si l'utilisateur n'est pas déjà connecté sur l'appli
 */
@Injectable({
	providedIn: "root"
})
export class OneSignalService extends BaseService {

	ONE_SIGNAL_NOT_EXIST = "Impossible d'accéder aux notifications. Si vous disposez de bloqueurs de publicité, veuillez les désactiver et ensuite rafraîchissez la page";
	ENABLE_PUSH_NOTIFICATION = "Veuillez modifier les réglages de notifications en choisissant l'option 'Autoriser' afin de vous abonner à nos notifications";
	rejectedNotificationStatus = false;

	constructor(private accountService: AccountService, public oneSignalCordova: OneSignal,
		 public toastController: ToastController, public globalService: GlobalService, private route: Router,private router:ActivatedRoute) {
		 super();
		 console.log("router",router);
	}

	/**
	 * Identifiant utilisateur
	 * généré par le site OneSignal à l'initialisation :
	 * cet identifiant dépend du navigateur
	 */
	oneSignalId: any;

	toggleChecked = false;
	alreadyInit = false;

	/**
	 * Initialisation (ne peut être executée qu'une seule fois)
	 * on récupère l'ID de l'utilisateur depuis la classe User, qu'on envoie
	 * en tag au site OneSignal (permettra ainsi plus tard d'envoyer des notifications à certains groupes)
	 * et on démarre la session OneSignal avec l'id ProfEnPoche
	 */
	initOneSignal() {
		return new Promise((resolve, reject) => {
		if (!this.globalService.isCordova) {
			if (!this.alreadyInit) {
					OneSignalDesc = window["OneSignal"] || [];
			}
			if (!this.alreadyInit) {
					OneSignalDesc.push([
							"init",
							{
								appId: "47930da2-dba2-43c3-b86f-c494da6df6d4",
								safari_web_id: "web.onesignal.auto.5e769637-3ad0-4829-bde6-e4b31d264ae2",
								autoRegister: false,
								// requiresUserPrivacyConsent: true, //pour la RGPD : consentement explicite
								allowLocalhostAsSecureOrigin: true,
								notifyButton: {
									enable: false
								},
							},
						]);
					this.alreadyInit = true;
					this.notificationStatus();
					this.activateNotifications();
					OneSignalDesc.addListenerForNotificationOpened(() => {
						this.route.navigateByUrl("/chat");
					});
				 }
		}
		resolve(true);
	});
}


/**
 * Ne pas afficher les notifications dans la page chat
 */

unableToReceiveNotifications() {
	if (!this.globalService.isCordova) {
			this.initOneSignal().then(() => {
				this.checkIfSubscribed().then(isEnabled => {
					if (isEnabled) {
						this.deconnect();
					}
				});
			});
	}
}

/**
 * 
 * Envoyer le tag de l'utilisateur pour recevoir des notifications
 */

activateNotifications() {
	if (!this.globalService.isCordova) {
		this.initOneSignal().then(() => {
			this.checkIfSubscribed().then(isEnabled => {
				if (isEnabled) {
					this.connect();
				} else if (isEnabled && this.route.url == "/chat"){
					this.deconnect();
				}
			});
		})
	}
}

	/**
	 * Afficher un message lors de l'utilisation d'un bloqueur de publicité
	 */

	async messageToast(message) {
		const toast = await this.toastController.create({
		  message,
		  duration:  7000,
		});
		toast.present();
	  }

	/**
	 * Vérifier si OneSignal a bien été  chargé
	 */

	isFunction() {
	    if (OneSignalDesc && OneSignalDesc.initialized) {
			return true;
		}
		   return false;
	}

	/**
	 * Une fois la session initialisée, on inscrit l'utilisateur au service de notifications
	 * (depuis le bouton toggle de la page profil)
	 */
	subscribe() {
		if (!this.globalService.isCordova) {
			if (this.isFunction()) {
				OneSignalDesc.push(() => {
					OneSignalDesc.sendTag("user_id", this.accountService.user.id, tagsSent => {});
					OneSignalDesc.setSubscription(true).then(data => {
					});
					this.toggleChecked = true;
					OneSignalDesc.push(["registerForPushNotifications"]);
					OneSignalDesc.on("subscriptionChange", isSubscribed => {
							OneSignalDesc.getUserId().then(userId => {
								this.oneSignalId = userId;
							});
						});
					});
			} else {
				this.toggleChecked = false;
				this.messageToast(this.ONE_SIGNAL_NOT_EXIST);
			}
		} else {
			this.oneSignalCordova.setSubscription(true);
			this.oneSignalCordova.sendTag("user_id", String(this.accountService.user.id));
		}
	}

	/**
	 * Envoie de tag au moment de connection
	 */
	connect() {
		if (this.globalService.isCordova) {
			this.oneSignalCordova.sendTag("user_id", String(this.accountService.user.id));
		} else {
			if (this.isFunction()) {
				OneSignalDesc.sendTag("user_id", this.accountService.user.id);
			}
		}
	}

	/**
	 * Suppression des tags au moment de deconnection
	 */

	deconnect() {
		if (this.globalService.isCordova) {
			this.oneSignalCordova.deleteTag("user_id");
		} else {
			console.log("is Initialized", this.isFunction())
			if (this.isFunction()) {
				OneSignalDesc.deleteTag("user_id");
			}
		}
	}

	/**
	 * Vérifier le statut de permission de norification
	 */

	notificationStatus() {
		if (Notification.permission === "denied") {
			this.rejectedNotificationStatus = true;
		}
	}

	/**
	 * Demande de permission pour activer les notifications
	 */

	permission() {
		if (!this.globalService.isCordova) {
			if (Notification.permission !== "granted") {
				this.toggleChecked = false;
				Notification.requestPermission().then(permission => {
					console.log("permission", permission);
					if (permission === "granted") {
						if (!this.toggleChecked) {
							this.initOneSignal();
							this.subscribe();
						} else {
							this.initOneSignal();
							this.unsubscribe();
						}
					} else if (permission === "denied") {
						this.rejectedNotificationStatus = true;
					}
				});
			} else {
				if (!this.toggleChecked) {
					this.unsubscribe();
				} else {
					this.initOneSignal();
					this.subscribe();
				}
			}
	} else {
		if (!this.toggleChecked) {
			this.unsubscribe();
		} else {
			this.subscribe();
		}
	}

	}

	/**
	 * Permet à l'utilisateur de se désinscire des notifications (toggle page profil)
	 */
	unsubscribe() {
		if (!this.globalService.isCordova) {
			if (this.isFunction()) {
				OneSignalDesc.setSubscription(false);
				this.toggleChecked = false;
			} else {
				this.toggleChecked = true;
				this.messageToast(this.ONE_SIGNAL_NOT_EXIST);
			}
		} else {
			this.oneSignalCordova.setSubscription(false);
		}
	}

	/**
	 * Récupérer l'ID OneSignal de l'utilisateur (fonction "native" OneSignal)
	 */
	getUserID() {
		OneSignalDesc.getUserId().then(userId => {
			this.oneSignalId = userId;
		});
	}

	/**
	 * Vérifier si l'utilisateur est déjà inscrit aux notifications push
	 * Si il est inscrit on récupère son ID OneSignal
	 * @returns Promise
	 */
	checkIfSubscribed() {
		return new Promise((resolve, reject) => {
			OneSignalDesc.push(() => {
				OneSignalDesc.isPushNotificationsEnabled(
					isEnabled => {
						if (isEnabled) {
							this.getUserID();
						} else {
						}
						resolve(isEnabled);
					},
					error => {
						console.log(error);
						reject(error);
					}
				);
			});
		});
	}

	checkSubscriptionChange() {
		OneSignalDesc.push(() => {
			// Occurs when the user's subscription changes to a new value.
			OneSignalDesc.on("subscriptionChange", function (isSubscribed) {});
			// This event can be listened to via the `on()` or `once()` listener.
		});
	}


	/**
	 * Utile en cas de "bug" ou si on veut en savoir plus sur les infos envoyées par OneSignal
	 * sur l'état de l'inscription aux notifs
	 * @returns Promise
	 */
	isPushNotificationsEnabledVerbose() {
		Promise.all([
			OneSignalDesc.isPushNotificationsEnabled(),
			OneSignalDesc.getUserId(),
			OneSignalDesc.getRegistrationId(),
			OneSignalDesc.getNotificationPermission(),
			OneSignalDesc.isOptedOut()
			// OneSignal.context.serviceWorkerManager.getActiveState()
		])
			.then(([isSubscribed, userId, registrationId, notificationPermission, optedOut]) => {
				console.log("Is Completely Subscribed:", isSubscribed);
				console.log("What is our OneSignal user ID?", userId);
				console.log("What is our push subscription token?", registrationId);
				console.log("What is the notification permission status?", notificationPermission);
				console.log("Are you manually opted out?", optedOut);
				// console.log("Is a service worker registered and active?
				// (should be false on Safari, otherwise should be 'Worker A (Main)')?", serviceWorkerActive);
				console.log("What is the current URL of this page?", location.href);
				console.log("What environment does OneSignal think it's in?", OneSignalDesc.sdkEnvironment.getWindowEnv());
			})
			.catch(e => {
				console.error("Issue determining whether push is enabled:", e);
			});
	}
}
