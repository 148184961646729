import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class BaseService {
	constructor() {}

	data_default = {
		urlImgLogo: "img/logo&ProfenPoche.png",
		urlImgTop: "",
		textIdentifiant: "Pseudo",
		textMDP: "Mot de passe",
		textRemember: "Se souvenir de moi ?",
		textConnexionButton: "Connexion",
		textCGU: "En vous connectant, vous acceptez nos conditions d'utilisation",
		urlLinkCGU: "https://profenpoche.com/cgv-cgu/",
		urlImgMiddle: "",
		textSignUpButton: "Cr\u00e9er un compte",
		divFacebookButton: true,
		divSignUpButton: "",
		divFreeTestButton: true,
		urlFreeTest: "https://www.planeteprofs.com/prof-en-poche/sinscrire/",
		textFreeTestButton: "Essayez gratuitement",
		urlLostPassword: "https://www.planeteprofs.com/my-account/lost-password/",
		textLostPassword: "Tu as oubli\u00e9 ton mot de passe ?",
		textFacebookButton: "Se connecter avec Facebook",
		tabStory: [
			{ type: "image", url: "https://www.planeteprofs.com/wp-content/uploads/2018/01/PEPsimple_JEUNESmdpi-e1540996412120.png" },
			{
				type: "text",
				text: "Prof en Poche, c'est des professeurs particuliers \u00e0 des prix d\u00e9fiants toute concurrence \ud83d\udcaa!"
			},
			{
				type: "text",
				text:
					"Prof en Poche c'est aussi une intelligence artificielle (I.A.) qui r\u00e9pond gratuitement \u00e0 tous les \u00e9l\u00e8ves de la 6\u00e8me au Bac pour les aider en math\u00e9matiques (fiche de cours, exercices, vid\u00e9os)."
			},
			{ type: "video", url: "https://profenpoche.com/wp-content/uploads/2018/01/prof-en-poche-vido-de-prsentation-2.mp4" }
		],
		urlImgLogoInscription: "img/logo&ProfenPoche.png",
		labelNom: "true",
		labelPrenom: "true",
		labelEmail: "true",
		labelLogin: "true",
		labelPassword: "true",
		labelConfirmPassword: "true",
		labelNumtel: "true",
		labelClasse: "true",
		geoOkTitle: "Bienvenue dans la m\u00e9diath\u00e8que {0}",
		geoOk:
			'<p style="text-align: center;">En partenariat avec Prof en Poche.<br/> Vous pouvez d\u00e8s maintenant demander l\'aide d\'un professeur en Math Physique et Chimie. <br/> Ce service se terminera \u00e0 : {0}.</p><ion-icon class="ion-checkmark-circled iconAlertGreen"></ion-icon>',
		geoKoTitle: "Localisation incorrecte",
		geoKo:
			'<p style="text-align: center;">Ce service n\'est pas disponible \u00e0 cette endroit.</p><ion-icon class="ion-close-circled iconAlertRed"></ion-icon>',
		geoNoGpsTitle: "Probl\u00e8me de localisation",
		geoNoGps: "Merci d'activer la fonction GPS pour nous permettre de te localiser.",
		customMatieresIcons	:	{Géographie: "https://profenpoche.com/wp-content/uploads/2021/01/Lea-etudiante-prof-en-poche.png"}
	};

	/**
	 *  URL pour les toutes requêtes API
	 */
	public postUrl = "https://www.planeteprofs.com/wp-content/plugins/simple-chat/apiProf.php";

	/**
	 * URL sauvegarde des fichiers
	 */
	public fileUrl = "https://www.planeteprofs.com/wp-content/uploads/Filechat";

	/**
	 * Sert à gérer les messages d'erreur
	 *
	 */
	handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error("An error occurred:", error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong.
			console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
		}
		// Return an observable with a user-facing error message.
		return throwError("Il y a eu un problème; veuillez réessayer plus tard.");
	}
}
