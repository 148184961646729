export class QCM {
    idQcm: number;
    idCourse: number;
    statement: any;

    constructor(statement,idQcm, idCourse){
        this.statement = statement;
        this.idQcm = idQcm;
        this.idCourse = idCourse;
        this.defineObject();
    }


    start() {
        console.log("statement", this.statement);
        return this.statement;
    }

    defineObject() {
        for (const currentStatement in this.statement) {
			if (this.statement.hasOwnProperty("object") && currentStatement === "object") {
				this.statement[currentStatement].id = `https://xapi.profenpoche/id/QCM_${this.idQcm}`;
				this.statement[currentStatement].definition.name["fr-FR"] =  "QCM";
				this.statement[currentStatement].definition.description["fr-FR"] = `QCM ayant l'identifiant ${this.idQcm}`;
				this.statement[currentStatement].definition.type =  `https://xapi.profenpoche/id/${this.idQcm}`;
				this.statement[currentStatement].objectType =  `QCM`;
			}
		  }
    }
}