import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { BigpicturePageRoutingModule } from './bigpicture-routing.module';

import { BigpicturePage } from './bigpicture.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    BigpicturePageRoutingModule
  ],
  declarations: [BigpicturePage]
})
export class BigpicturePageModule {}
