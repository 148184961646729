import { AlertController, LoadingController } from "@ionic/angular";
import { ChatService } from "src/app/services/chat.service";
import { ChatMessage } from "src/app/models/chatMessage";
import { BaseService } from "src/app/services/base.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Camera, CameraOptions } from "@ionic-native/camera/ngx";
import { Platform } from "@ionic/angular";
import { ChangeDetectorRef, ElementRef } from "@angular/core";
import { AfterViewInit } from "@angular/core";
import { ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Component, OnInit } from "@angular/core";
import { AccountService } from "src/app/services/account.service";
// import { ImageCroppedEvent, ImageTransform } from "ngx-image-cropper";
import { GlobalService } from "src/app/services/global.service";
import Cropper from 'cropperjs';
import { DarkModeService } from "src/app/services/dark-mode.service";


@Component({
	selector: "app-joindre-fichier",
	templateUrl: "./joindre-fichier.page.html",
	styleUrls: ["./joindre-fichier.page.scss"]
})
export class JoindreFichierPage extends BaseService implements AfterViewInit {
	constructor(
		private modalCtrl: ModalController,
		public platform: Platform,
		private camera: Camera,
		public darkMode: DarkModeService,
		private http: HttpClient,
		public globalService: GlobalService,
		private chatService: ChatService,
		private alerteController: AlertController,
		private cd: ChangeDetectorRef,
		private loadingController : LoadingController,
		private accountService: AccountService
	) {
		super();
	}
	static cropperData;
	static cropperImage;
	static croppedCanvas;

	@ViewChild("canvasImage") canvasImage: ElementRef;
	@ViewChild("selectFile") selectFile: ElementRef;
	@ViewChild("fichier", {static : false}) fichier: ElementRef;
	public imageDestination: string;
	private cropper: Cropper;
	private rotateLevel = 0;
	public imageExist : boolean;
	event: any;
	fichierBase64: string;
	// transform: ImageTransform = {};
	@ViewChild("cropperContainer", { static: false }) cropperContainer: ElementRef;
	croppieObj: any;

	zoomOut() {
		if (JoindreFichierPage.cropperImage.zoomLevel) {
			JoindreFichierPage.cropperImage.zoomLevel -= 0.03;
			this.cropper.zoomTo(JoindreFichierPage.cropperImage.zoomLevel);
		}
	}

	zoomIn() {
		if (JoindreFichierPage.cropperImage.zoomLevel) {
			JoindreFichierPage.cropperImage.zoomLevel += 0.03;
			this.cropper.zoomTo(JoindreFichierPage.cropperImage.zoomLevel);
		}
	}

	imageLoaded() {
		this.imageExist = true;
	}

	ngAfterViewInit() {
	if (this.platform.is("mobile")) {
		if (this.event) {
			this.verifierFormat(this.event);
		}
	}

	this.cd.detectChanges();
	}


	/**
	 * Ouvre l'explorateur de fichier et test le format du fichier choisi
	 * Retourne une promesse
	 *
	 * @param  event
	 * @returns {Promise} si le fichier et au format gif,png,jpg,jpeg on retourne sa base 64 sinon on affiche une erreur
	 */

	joindreFichier(event): Promise<any> {
		return new Promise((resolve, reject) => {
			const fichier = event.target.files[0];
			const lecteur = new FileReader();
			lecteur.readAsDataURL(fichier);
			lecteur.onload = () => {
				if (lecteur.result.slice(5, 27) === "application/pdf;base64") {
					this.envoiServeur(fichier);
				} else if (
				lecteur.result.slice(5, 22) === "image/jpeg;base64" ||
				lecteur.result.slice(5, 21) === "image/png;base64" ||
				lecteur.result.slice(5, 21) === "image/gif;base64") {
					resolve(lecteur.result);
				} else {
					event.target.value = "";
					reject("format de fichier invalide");
				}
			};
		});
	}

	/**
	 * Sur pc, initialise la base 64 de l'image à afficher sinon emet un pop up d'erreut
	 *
	 * @param event
	 */

	verifierFormat(event) {
		this.joindreFichier(event)
			.then((base64) => {
				this.fichierBase64 = base64;
				setTimeout(() => {
				this.cropper = new Cropper(this.fichier.nativeElement, {
					viewMode: 2,
					autoCropArea : 1,
					cropBoxResizable: true,
					zoomOnWheel: true,
					crop() {
						JoindreFichierPage.cropperData = this.cropper.getContainerData();
						JoindreFichierPage.croppedCanvas = this.cropper.getCroppedCanvas();
						JoindreFichierPage.croppedCanvas.base64 = this.cropper.getCroppedCanvas().toDataURL();
						JoindreFichierPage.cropperImage = this.cropper.getImageData();
						JoindreFichierPage.cropperImage.zoomLevel = JoindreFichierPage.cropperImage.width / JoindreFichierPage.cropperImage.naturalWidth;
					},
					});
				}, 0);
			})
			.catch(async err => {
				const popUpErreur = await this.alerteController.create({
					cssClass: "popUpErreur",
					header: "Erreur dans le format du fichier",
					message: "Seuls les fichiers au format *png, *jpg, *jpeg, *gi, *pdf peuvent être envoyés",
					buttons: [
						{
							text: "Ok",
							role: "oK",
							cssClass: "buttonOK"
						}
					]
				});
				await popUpErreur.present();
			});
	}

	/**
	 * fais la requête au serveur, puis demande le faux message
	 */

	async envoiServeur(pdf?) {
		let itemToSend, dataToBeSend, url, httpOptions;
		if (pdf) {
			url = "https://www.planeteprofs.com/wp-content/plugins/simple-chat/submit.php";
			const formData = new FormData();
			formData.append("fileToUpload", pdf);
			formData.append("up_channel_id", String(this.accountService.user.channelId));
			dataToBeSend  = formData;
		} else {
			url = this.postUrl + "?action=app_receive_photo";
			itemToSend = JoindreFichierPage.croppedCanvas.base64;
			dataToBeSend  = { photoupload: itemToSend};
		}

		this.chargement();
		this.http.post(url, dataToBeSend, httpOptions ).subscribe(async (fileUrl: any) => {
			if (pdf) {
				const formData = new FormData();
				formData.append("channel_id", String(this.accountService.user.channelId));
				formData.append("file_name", fileUrl.name);
				formData.append("image", "0");

				this.http.post(this.postUrl + "?action=save_upload_msg", formData ).subscribe(async () => {
					this.preparerMessageChat(this.fileUrl + "/" + fileUrl.name.toString());
					await this.loadingController.dismiss();
					this.dismiss();
				});
			} else {
				this.preparerMessageChat(fileUrl.toString());
				await this.loadingController.dismiss();
				this.dismiss();
			}
		});
	}


	/**
	 * Chargement
	 */
	async chargement() {
		const loading = await this.loadingController.create({
			message: "Envoi en cours ...",
		});
		await loading.present();
	}

	/**
	 * Affiche un faux message sur le tchat
	 *
	 * @param {string} url url de l'image
	 */

	preparerMessageChat(url: string) {
		const message: ChatMessage = {
			title: this.accountService.user.role == "professeur" ? "Prof en Poche" : this.accountService.user.displayName,
			from: this.accountService.user.id,
			role: this.accountService.user.role,
			icon: null,
			showtitle: false,
			message: url,
			message_date: "Now",
			classes: "ToDelete",
			type: "image",
			url: "",
			url_image: "https://profenpoche.com/webview-images/?id_user=" + parseInt(localStorage.getItem("iduser")) + "&url=" + url,
			heure: null
		};

		this.chatService.chatMessages = this.chatService.chatMessages.concat(message);
	}

	/**
	 * quitte le modal
	 */

	dismiss() {
		this.modalCtrl.dismiss({
			dismissed: true
		});
	}
}
